<template>
  <div class="back-drop" v-if="showDialog">
    <div class="dialog-card">
      <button class="close ripple" @click="onClickClose">
        <i class="material-icons-round">close</i>
      </button>
      <div class="title">
        {{ $t('verificationDialog.enterVerificationCode') }}
      </div>
      <div
        v-if="!isExceedsLimitSmsPerDay && !isExceedsLimitVerifyPerHour"
        class="otp-input-group"
        @click="onClickOtpInputGroup"
      >
        <div
          v-for="n in otpLength"
          :key="`otp-digit-${n}`"
          class="digit-preview ripple"
          :class="{
            active: n - 1 < otp.length
          }"
        >
          {{ otp[n - 1] || '' }}
        </div>
        <input ref="otp-input" type="number" step="any" class="no-spin-button" pattern="\d*" autocomplete="off" autocorrect="off" autocapitalize="off" :maxlength="otpLength" v-model="otp">
      </div>
      <div class="guide">
        {{ $t('verificationDialog.guide') }} <span class="tel-number">{{ telNumber }}</span>
      </div>
      <div class="guide">
        {{ $t('verificationDialog.guide1') }}
      </div>
      <div class="guide error" v-if="isExceedsLimitSmsPerDay">
        {{ $t('verificationDialog.guideExceedsLimitSmsPerDay') }}
      </div>
      <div class="guide error" v-if="isFailedSendingSms">
        {{ $t('verificationDialog.guideFailedSendingSms') }}
      </div>
      <div class="guide error" v-if="isExceedsLimitVerifyPerHour">
        {{ $t('verificationDialog.guideExceedsLimitVerifyPerHour') }}
      </div>
      <div class="guide error" v-if="isIncorrectVerificationCode">
        {{ $t('verificationDialog.guideIncorrectVerificationCode') }}
      </div>
      <div class="action">
        <button class="ripple outline" @click="onClickResend" :disabled="!canResend">
          {{ resendButtonLabel }}
        </button>
        <button class="ripple" @click="onClickSubmit" :disabled="!canSubmit">
          {{ $t('verificationDialog.submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    telNumber: {
      type: String,
      default: ''
    },
    userData: {
      type: Object,
      default: null
    }
  },
  computed: {
    otpLength () {
      return 4
    },
    canResend () {
      const self = this
      return self.countDown <= 0 && !self.isResendingOtp
    },
    canSubmit () {
      const self = this
      return self.otp.length === self.otpLength && !self.isVerifying
    },
    resendButtonLabel () {
      const self = this
      return self.countDown > 0 ? `${self.$t('verificationDialog.resend')} (${self.countDown})` : self.$t('verificationDialog.resend')
    }
  },
  data () {
    return {
      user: null,
      showDialog: false,
      otp: '',
      countDown: 0,
      countDownTask: null,
      isResendingOtp: false,
      isVerifying: false,
      isExceedsLimitSmsPerDay: false,
      isFailedSendingSms: false,
      isExceedsLimitVerifyPerHour: false,
      isIncorrectVerificationCode: false
    }
  },
  mounted () {
    const self = this
    self.user = self.userData
    self.$root.$on('loginComplete', self.onLoginComplete)
  },
  methods: {
    onLoginComplete (user) {
      const self = this
      user.username = user.name
      self.user = user
    },
    onClickOtpInputGroup () {
      const self = this
      if (self.$refs['otp-input']) {
        self.$refs['otp-input'].focus()
      }
    },
    onClickClose () {
      const self = this
      self.$emit('close')
      self.showDialog = false
    },
    onClickResend () {
      const self = this
      self.resend(false)
    },
    async onClickSubmit () {
      const self = this
      self.isVerifying = true
      self.isExceedsLimitSmsPerDay = false
      self.isFailedSendingSms = false
      self.isExceedsLimitVerifyPerHour = false
      self.isIncorrectVerificationCode = false

      const params = {
        phone_no: self.telNumber,
        code: self.otp
      }

      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.user
      if (userSession) {
        if (userSession.longdousertoken !== '' && userSession.longdousertoken !== null && userSession.longdousertoken !== undefined) {
          params.username = userSession.name || userSession.username
          params.ldtoken = userSession.longdousertoken
        }
      }

      const result = await self.api.verifyPhoneNo(params)
      self.otp = ''
      if (result.data.status.code === 200) {
        if (self.countDownTask) {
          clearInterval(self.countDownTask)
          self.countDownTask = null
        }
        self.$emit('success')
        self.showDialog = false
      } else if (result.data.status.code === 503) {
        if (result.data.status.error_code === 'overlimit_phoneno_verification') {
          self.isExceedsLimitVerifyPerHour = true
        }
        if (result.data.status.error_code === 'incorrect_code_verification') {
          self.isIncorrectVerificationCode = true
        }
      }
      self.isVerifying = false
    },
    async resend (isFirst = false) {
      const self = this
      if (self.isVerifying) {
        return false
      }
      self.isResendingOtp = true
      self.isExceedsLimitSmsPerDay = false
      self.isFailedSendingSms = false
      self.isExceedsLimitVerifyPerHour = false
      self.isIncorrectVerificationCode = false

      if (self.countDownTask) {
        clearInterval(self.countDownTask)
        self.countDownTask = null
        self.countDown = 0
      }
      self.otp = ''

      const params = {
        phone_no: self.telNumber,
        resend: isFirst ? 0 : 1
      }

      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.user
      if (userSession) {
        if (userSession.longdousertoken !== '' && userSession.longdousertoken !== null && userSession.longdousertoken !== undefined) {
          params.username = userSession.name || userSession.username
          params.ldtoken = userSession.longdousertoken
        }
      }
      const result = await self.api.sendSmsForPhoneNoVerification(params)
      if (result.data.status.code === 200) {
        self.countDown = 61
        self.countDownTask = setInterval(() => {
          if (self.countDown > 0) {
            self.countDown--
          } else {
            clearInterval(self.countDownTask)
            self.countDownTask = null
            self.countDown = 0
          }
        }, 1000)
      } else if (result.data.status.code === 503) {
        if (result.data.status.error_code === 'overlimit_sms') {
          self.isExceedsLimitSmsPerDay = true
        }
        if (result.data.status.error_code === 'sendsms_failed') {
          self.isFailedSendingSms = true
        }
      }

      self.isResendingOtp = false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        const self = this
        self.$nextTick(() => {
          self.showDialog = self.value
        })
      }
    },
    showDialog () {
      const self = this
      self.$nextTick(() => {
        self.$emit('input', self.showDialog)
        if (self.showDialog) {
          self.onClickOtpInputGroup()
          self.resend(true)
        }
      })
    },
    otp (newOtp, oldOtp) {
      const self = this
      if (newOtp.length > 4) {
        self.otp = oldOtp
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.back-drop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  top: 0px;
  left: 0px;
}

.dialog-card {
  padding: 24px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;

  button.close {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    i {
      color: $grey;
    }
  }

  .title {
    font-family: Prompt;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    color: $primary;
  }

  .guide {
    font-size: 14px;
    color: $lightGrey;
    margin: 3px 0px;

    &.error {
      color: $red;
    }
  }

  .tel-number {
    color: $grey;
    font-size: 16px;
    font-weight: bold;
  }

  .action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    button {
      height: 28px;
      background-color: $primary;
      padding: 0 12px;
      border-radius: 14px;
      color: white;
      border: 1px solid $primary;
      font-family: Prompt;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px 6px;
      transition: background-color 0.3s ease 0s,
        color 0.3s ease 0s;

      &.outline {
        color: $primary;
        background-color: white;
      }

      &:disabled {
        background-color: $lightGrey;
        border: 1px solid $lightGrey;
        cursor: default;
      }

      &:disabled.outline {
        color: $lightGrey;
        background-color: white;
      }
    }
  }
}

.otp-input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  width: 100%;
  max-width: (62px * 4);
  height: 56px;
  margin: 24px auto;
  cursor: pointer;

  .digit-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 56px;
    box-sizing: border-box;
    font-family: Prompt;
    font-size: 32px;
    border-radius: 6px;
    border: 2px solid $extraLightGrey;
    margin: 0px 3px;

    &.active {
      border: 2px solid $extraLightPrimary;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: 0;
    top: 0px;
    left: 0px;
    cursor: pointer;
  }
}
</style>
