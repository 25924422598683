<template>
  <div ref="text-field-suggest-widget" class="text-field-suggest-widget" :class="{invalid: invalid}">
    <!-- https://mmtech.slack.com/archives/C02FDEMLP/p1646715817349999?thread_ts=1644843554.180729&cid=C02FDEMLP -->
    <!-- <input ref="keyword-input" type="text" autocomplete="none" :value="keyword" :placeholder="placeholder" @input="onInput" onfocus="this.select();"> -->
    <input ref="keyword-input" type="text" autocomplete="none" :value="keyword" :placeholder="placeholder" @input="onInput" @change="onChange">
    <button class="clear" v-if="keyword !== ''" @click.stop="clear">
      <i class="material-icons-round clear">close</i>
    </button>
    <div class="suggest-container" v-if="showSuggest">
      <div ref="suggest-list" class="suggest-list">
        <div class="suggest-row"
          v-for="(suggest, i) in filteredSuggests"
          :key="i" @click="select(suggest)"
        >
          <div class="name">
            {{ suggest }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextFieldSuggestWidget',
  props: {
    value: null,
    suggests: {
      type: Array,
      default: () => {
        return []
      }
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    invalid: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      keyword: '',
      isShowSuggest: false
    }
  },
  mounted () {
    var self = this
    window.addEventListener('click', self.clickOnWindow)
    self.keyword = self.value
  },
  computed: {
    filteredSuggests () {
      var self = this
      return (self.suggests || []).filter(suggest => {
        var isMatch = suggest.toLowerCase().indexOf(self.keyword.toLowerCase()) !== -1
        var isEmptyKeyword = self.keyword === ''
        return isMatch || isEmptyKeyword
      })
    },
    showSuggest () {
      const self = this
      return self.isShowSuggest && self.filteredSuggests.length > 0
    }
  },
  methods: {
    clickOnWindow (e) {
      var self = this
      self.isShowSuggest = (self.$refs['text-field-suggest-widget'] === e.target || self.$refs['text-field-suggest-widget'].contains(e.target))
      self.$nextTick(() => {
        if (!self.showSuggest) {
          return false
        }
        const el = self.$refs['suggest-list']
        if (el) {
          const rect = el.getBoundingClientRect()
          const isInViewport = (rect.top - 54) >= 0 &&
            rect.left >= 0 &&
            (rect.bottom - 54) <= ((window.innerHeight || document.documentElement.clientHeight) - 54 - 48) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          if (!isInViewport) {
            el.scrollIntoView(false)
          }
        }
      })
    },
    select (value) {
      var self = this
      // self.keyword = value
      self.$emit('select', value)
      self.$nextTick(() => {
        self.isShowSuggest = false
      })
    },
    onInput (e) {
      var self = this
      self.keyword = e.target.value
      self.isShowSuggest = true
    },
    clear () {
      var self = this
      self.keyword = ''
      self.$nextTick(() => {
        self.$refs['keyword-input'].focus()
      })
    }
  },
  watch: {
    keyword () {
      var self = this
      self.$nextTick(() => {
        self.$emit('input', self.keyword)
      })
    },
    value (newValue) {
      var self = this
      self.$nextTick(() => {
        self.keyword = newValue
      })
    },
    showSuggest (newValue) {
      var self = this
      self.$nextTick(() => {
        if (newValue) {
          self.$refs['keyword-input'].focus()
        }
      })
    }
  },
  beforeDestroy () {
    var self = this
    window.removeEventListener('click', self.clickOnWindow)
  }
}
</script>

<style scoped lang="scss">
.text-field-suggest-widget {
  position: relative;

  input[type="text"] {
    outline: none;
    font-size: 16px;
    padding: 0px 36px 0px 12px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    width: 100%;
    height: 42px;
    line-height: 42px;
    appearance: none;
    margin-top: 3px;

    &::placeholder {
      color: #afafaf;
      font-family: Prompt;
    }
  }

  &.invalid {
    input[type="text"] {
      border: 1px solid $red;
    }
  }

  button.clear {
    position: absolute;
    top: 3px;
    right: 0px;
    width: 36px;
    height: 42px;
    padding: 0px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    i {
      font-size: 18px;
      color: $lightGrey;
    }
  }

  .suggest-container {
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 100%;
    top: 100%;
    left: 0px;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    z-index: 20;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    .suggest-list {
      overflow-y: auto;
      max-height: 200px;

      &::-webkit-scrollbar {
        background-color: rgb(251, 251, 251);
      }
      &::-webkit-scrollbar-thumb {
        border: 2px solid rgb(251, 251, 251);
      }

      .suggest-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 42px;
        padding: 9px;
        box-sizing: border-box;
        cursor: pointer;
        width: 100%;

        &:hover {
          background-color: rgba(56, 148, 238, 0.1);
        }

        .icon-category-container {
          height: 24px;
          width: 24px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;

          img {
            max-height: 24px;
            max-width: 24px;
          }
        }

        .name {
          width: 100%;
          padding: 2px 0px 0px 0px;
          font-size: 16px;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>
