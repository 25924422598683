<template>
  <div class="report-place" v-if="userData">
    <div class="header" v-if="!hideTitleBar">
      <div class="title">{{ title }}</div>
      <button class="close ripple" @click="onClickClose">
        <i class="material-icons-round">close</i>
      </button>
    </div>
    <div class="form" :class="{'hide-title-bar': hideTitleBar}">
      <div class="place-name">{{ placeName }}</div>
      <div class="field">
        <label>{{ $t('reportPlace.reason') }}</label>
        <textarea rows="5" v-model="reason"></textarea>
      </div>
    </div>
    <div class="action">
      <button class="ripple" @click="submitReportPlace" :disabled="!isValid || isSubmitingReportPlace">{{ submitButtonLabel }}</button>
    </div>
    <div class="loading-overlay" v-if="isSubmitingReportPlace">
      <img v-if="reportErrorMessage === '' && reportSuccessMessage === ''" src="@/assets/img/loading.gif">
      <span class="error-message" v-if="reportErrorMessage !== ''">{{ reportErrorMessage }}</span>
      <span class="success-message" v-if="reportSuccessMessage !== ''">{{ reportSuccessMessage }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReportPlace',
  props: {
    userData: {
      type: Object,
      default: null
    },
    detail: {
      type: Object,
      default: null
    },
    hideTitleBar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      const self = this
      return self.$t('reportPlace.title')
    },
    placeName () {
      const self = this
      return self.detail[`name_${self.$i18n.locale}`] || self.detail[`name_${self.$i18n.locale === 'th' ? 'en' : 'th'}`]
    },
    submitButtonLabel () {
      const self = this
      return self.$t('reportPlace.submit')
    },
    isValid () {
      const self = this
      let isValid = true
      isValid = isValid && self.reason.trim() !== ''
      return isValid
    }
  },
  data () {
    return {
      langMode: this.$root.$i18n.locale,
      reason: '',
      isSubmitingReportPlace: false,
      reportSuccessMessage: '',
      reportErrorMessage: ''
    }
  },
  created () {
    document.querySelector('body').style.backgroundColor = 'rgb(255, 255, 255)'
    document.querySelector('#splash-screen-logo').style.opacity = '0'
    document.querySelector('#splash-screen-logo').style.zIndex = '-99'
    document.querySelector('#splash-screen-logo').style.top = '50%'
    document.querySelector('#splash-screen-bg').style.opacity = '0'
    document.querySelector('#splash-screen-bg').style.zIndex = '-99'
    document.querySelector('#splash-screen-bg').style.animation = 'unset'
  },
  async mounted () {
    const self = this
    let userSession = await self.utility.getUserSession()
    userSession = userSession || self.userData
    if (!userSession) {
      self.$root.$emit('showLongdoLoginForm')
    }
  },
  methods: {
    onClickClose () {
      const self = this
      if (self.isOnMobileApp) {
        self.$router.replace({
          name: 'Place',
          hash: '',
          params: {
            poiId: self.$route.params.poiId
          },
          query: {}
        })
      } else if (window.history.length <= 2) {
        self.$router.replace({
          name: 'Main',
          hash: '',
          params: {},
          query: {}
        })
      } else {
        this.$router.back()
      }
    },
    async submitReportPlace () {
      const self = this

      if (self.isSubmitingReportPlace) {
        return false
      }
      self.isSubmitingReportPlace = true

      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      if (!(userSession || false)) {
        self.isSubmitingReportPlace = false
        return false
      }
      if (!(userSession.longdousertoken || false)) {
        self.isSubmitingReportPlace = false
        return false
      }

      const data = {
        username: userSession.name || userSession.username,
        ldtoken: userSession.longdousertoken,
        txtReason: self.reason.trim(),
        ooiid: self.detail.id
      }
      const result = await self.api.reportPlace(data)
      if (result.status === 200 && result.data) {
        if (result.data === 'Your request has been emailed to the webmaster.') {
          const dataForSuccessEvent = { poiId: data.ooiid }
          self.reportErrorMessage = self.$t('reportPlace.defaultReportSuccessMessage')
          setTimeout(() => {
            self.isSubmitingReportPlace = false
            self.reportSuccessMessage = ''
            self.$emit('success', dataForSuccessEvent)
          }, 2 * 1000)
        }
      } else {
        self.reportErrorMessage = self.$t('reportPlace.defaultReportErrorMessage')
        setTimeout(() => {
          self.isSubmitingReportPlace = false
          self.reportErrorMessage = ''
        }, 2 * 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.report-place {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 150;
  overflow: hidden;
  overflow-y: auto;

  .header {
    width: 100%;
    min-height: 54px;
    background-color: $darkPrimary;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: linear-gradient(0deg, $primary, $darkPrimary);
    border-bottom: 4px solid $lightPrimary;

    .title {
      font-family: Prompt;
      color: white;
      font-size: 18px;
      text-align: center;
      width:calc(100% - 72px);
    }

    button.close {
      padding: 0px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0%, -50%);
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: white;
        font-size: 24px;
      }
    }
  }

  .form {
    width: 100%;
    height: calc(100% - 54px - 48px);
    overflow: hidden;
    overflow-y: auto;

    &.hide-title-bar {
      height: calc(100% - 48px);
    }

    .place-name {
      padding: 12px 12px 0px 12px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Prompt;
      font-weight: bold;
    }

    .field {
      display: flex;
      flex-direction: column;
      padding: 12px 12px 0px 12px;
      box-sizing: border-box;

      label {
        font-family: Prompt;
        font-size: 12px;
      }

      textarea {
        outline: none;
        font-size: 16px;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: 100%;
        min-height: 42px;
        line-height: 21px;
        appearance: none;
        resize: none;
        margin-top: 6px;
      }
    }
  }

  .action {
    width: 100%;
    height: 48px;

    button {
      width: 100%;
      height: 100%;
      border: none;
      background-color: $darkPrimary;
      color: white;
      font-family: Prompt;
      font-size: 18px;
      transition: background-color 0.3s ease 0s;

      &:disabled {
        background-color: $lightGrey;
      }
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);

    .error-message,
    .success-message {
      font-family: 'Prompt';
      font-size: 16px;
      padding: 12px;
      text-align: center;
      color: rgb(255, 255, 255);
      max-width: 230px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
    }
  }
}
</style>
