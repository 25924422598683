<template>
  <div class="edit-place">
    <CreatePlace
      v-if="!isLoading && (!isEditMode || (isEditMode && canEdit)) && !isReport"
      :detail="detail"
      :userData="user"
      :hideTitleBar="hideTitleBar"
      :initialLocation="!isEditMode ? location : null"
      :initialZoom="zoom"
      :hideFullDescription="platform === 'android' || platform === 'ios'"
      :isGeolocationMobileAppDisabled="isGeolocationMobileAppDisabled"
      @success="onSuccess"
    />
    <ReportPlace
      v-if="!isLoading && isReport"
      :detail="detail"
      :userData="user"
      @success="onSuccess"
    />
  </div>
</template>

<script>
import { Settings } from 'luxon'
import CreatePlace from '@/components/CreatePlace.vue'
import ReportPlace from '@/components/ReportPlace.vue'

export default {
  name: 'EditPlace',
  components: {
    CreatePlace,
    ReportPlace
  },
  props: {
    userData: {
      type: Object,
      default: null
    },
    platform: {
      type: String,
      default: ''
    },
    location: {
      type: Object,
      default: () => {
        return {
          lat: 13.729319976238605,
          lon: 100.53589001297951
        }
      }
    },
    zoom: {
      type: Number,
      default: null
    },
    isReport: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hideTitleBar () {
      const self = this
      return self.platform !== ''
    },
    isEditMode () {
      const self = this
      return self.poiId !== null
    },
    poiId () {
      const self = this
      return self.$route.params.poiId || null
    }
  },
  data () {
    return {
      width: window.innerWidth,
      isLoading: true,
      detail: null,
      user: null,
      canEdit: false,
      isGeolocationMobileAppDisabled: false
    }
  },
  created () {
    const self = this
    if (self.platform !== '') {
      return false
    }
    document.querySelector('body').style.backgroundColor = process.env.VUE_APP_PRIMARY_COLOR
    document.querySelector('#splash-screen-bg').style.opacity = '0.25'
    document.querySelector('#splash-screen-bg').style.zIndex = '0'
    document.querySelector('#splash-screen-bg').style.animation = 'loading-slide 20s linear infinite'
  },
  async mounted () {
    const self = this
    if ('lang' in self.$route.query || 'locale' in self.$route.query) {
      self.$i18n.locale = (self.$route.query.lang || self.$route.query.locale) === 'th' ? 'th' : 'en'
      localStorage.lang = self.$i18n.locale
      Settings.defaultLocale = self.$i18n.locale
    } else {
      if (localStorage.lang) {
        self.$i18n.locale = localStorage.lang
        Settings.defaultLocale = localStorage.lang
      }
    }

    self.$root.$on('loginComplete', self.onLoginComplete)
    self.$root.$on('loginFormClose', self.onLoginFormClose)

    self.$root.$on('resize', (size) => {
      self.width = size.width
    })
    self.$root.$on('showLongdoLoginForm', async value => {
      await self.showLongdoLoginForm()
    })
    let userSession = await self.utility.getUserSession()
    userSession = userSession || self.user

    self.user = userSession || self.userData
    // self.user = self.longdoComAPI.getUserInfo() || self.userData
    if (!self.user) {
      document.querySelector('body').style.backgroundColor = process.env.VUE_APP_PRIMARY_COLOR
      document.querySelector('#splash-screen-bg').style.opacity = '0.25'
      document.querySelector('#splash-screen-bg').style.zIndex = '0'
      document.querySelector('#splash-screen-bg').style.animation = 'loading-slide 20s linear infinite'
      document.querySelector('#splash-screen-logo').style.opacity = '1'
      document.querySelector('#splash-screen-logo').style.zIndex = '1'
      document.querySelector('#splash-screen-logo').style.top = 'calc((100% - 381px) / 4)'
      self.$root.$emit('showLongdoLoginForm')
      if (!self.isOnMobileApp) {
        document.querySelector('.pp_overlay.pp_overlay_longdocom').style.backgroundColor = 'transparent'
      }
    } else {
      self.prepareData()
    }
    if (window.isGeolocationMobileAppDisabled !== null) {
      self.isGeolocationMobileAppDisabled = window.isGeolocationMobileAppDisabled
    }
    self.$root.$on('MobileAppGeolocationEnabled', self.onMobileAppGeolocationEnabled)
    self.$root.$on('MobileAppGeolocationDisabled', self.onMobileAppGeolocationDisabled)
    self.storePathToNativeAnalytic(self.$route)
  },
  methods: {
    onMobileAppGeolocationEnabled () {
      const self = this
      self.$root.$emit('closeMobilePermissionDialog')
      self.isGeolocationMobileAppDisabled = false
    },
    onMobileAppGeolocationDisabled () {
      const self = this
      self.isGeolocationMobileAppDisabled = true
    },
    async showLongdoLoginForm () {
      const self = this
      if (self.isOnMobileApp) {
        let loginResult
        try {
          loginResult = await self.lji.loginLongdo()

          // {<{username: String, longdoToken: String}>} result
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        if (loginResult?.username && loginResult?.longdoToken) {
          try {
            await self.lji.addStorage({
              key: 'username',
              value: loginResult.username
            })
            // {Boolean} result
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
          try {
            await self.lji.addStorage({
              key: 'ldtoken',
              value: loginResult.longdoToken
            })
          } catch (error) {
            // {<{code: Number, message: String}>} error
            console.log(error)
          }
        }
        const user = await self.utility.getUserSession()
        if (user) {
          self.$root.$emit('loginComplete', user)
        } else {
          console.log('error getUserSession')
        }
      } else {
        self.longdoAccount.showLongdoLoginForm()
      }
    },
    onLoginComplete (user) {
      const self = this
      self.user = user
      self.longdoAccount.closePrettyPhoto()
      self.prepareData()
    },
    onLoginFormClose () {
      const self = this
      if (!self.user) {
        switch (self.platform) {
          case 'android': {
            try {
              Android_Longdo_Map.onCloseLoginForm()
            } catch (e) {
              console.log(e)
            }
            break
          }
          case 'ios': {
            try {
              window.webkit.messageHandlers.onCloseLoginForm.postMessage({})
            } catch (e) {
              console.log(e)
            }
            break
          }
          case 'desktop': {
            window.parent.postMessage(['onCloseLoginForm', {}], '*')
            break
          }
          default: {
            self.goToFallbackPage()
          }
        }
      }
    },
    onSuccess (data) {
      const self = this
      // if (self.width >= 992) {
      //   window.parent.postMessage(['onPlaceFormSuccess', data.poiId], '*')
      //   if (self.isEditMode) {
      //     self.$router.replace(`/p/${data.poiId}`)
      //   }
      //   return
      // }
      switch (self.platform) {
        case 'android': {
          try {
            Android_Longdo_Map.onPlaceFormSuccess(JSON.stringify(data))
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'ios': {
          try {
            window.webkit.messageHandlers.onPlaceFormSuccess.postMessage(data)
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'desktop': {
          window.parent.postMessage(['onPlaceFormSuccess', data.poiId], '*')
          if (self.isEditMode) {
            window.location.href = `/p/${data.poiId}`
          }
          break
        }
        default: {
          self.$router.replace(`/p/${data.poiId}`)
          self.$root.$emit('onPlaceFormSuccess')
        }
      }
    },
    goToFallbackPage () {
      const self = this
      if (self.isEditMode) {
        self.$router.replace({
          name: 'Place',
          params: {
            poiId: self.$route.params.poiId
          }
        })
      } else {
        self.$router.replace({
          name: 'Main',
          params: {},
          query: {},
          hash: ''
        })
      }

      self.hideBg()
    },
    async prepareData () {
      const self = this

      // to check condition first
      self.isLoading = true

      if (self.isEditMode) { // edit mode
        await self.getPlaceDetail()
        self.canEdit = self.detail.permission.edit
        self.canDelete = self.detail.permission.delete
        if (self.isReport) {
        // if not match the condition that show report button -> redirect to edit
          if (!(!self.canEdit || (self.canEdit && !self.canDelete))) {
            const userSession = await self.utility.getUserSession()
            self.$router.replace({
              name: 'EditPlace',
              params: {
                poiId: self.$route.params.poiId,
                userData: userSession || self.userData
              // userData: self.longdoComAPI.getUserInfo() || self.userData
              }
            })
          }
        }
        if (self.detail) {
          if (self.canEdit) {
            await self.extractAddress()
          } else {
            self.onCannotEdit()
          }
        } else {
          self.onCannotGetDetail()
        }
        self.isLoading = false
      } else { // create mode
        self.isLoading = false
      }
    },
    async onCannotEdit () {
      const self = this
      const userSession = await self.utility.getUserSession()
      self.$router.replace({
        name: 'ReportPlace',
        params: {
          poiId: self.$route.params.poiId,
          userData: userSession || self.userData
          // userData: self.longdoComAPI.getUserInfo() || self.userData
        }
      })
      // switch (self.platform) {
      //   case 'android': {
      //     try {
      //       Android_Longdo_Map.onCannotEdit()
      //     } catch (e) {
      //       console.log(e)
      //     }
      //     break
      //   }
      //   case 'ios': {
      //     try {
      //       window.webkit.messageHandlers.onCannotEdit.postMessage({})
      //     } catch (e) {
      //       console.log(e)
      //     }
      //     break
      //   }
      //   case 'desktop': {
      //     window.parent.postMessage(['onCannotEdit', {}], '*')
      //     self.goToFallbackPage()
      //     break
      //   }
      //   default: {
      //     self.goToFallbackPage()
      //   }
      // }
    },
    onCannotGetDetail () {
      const self = this
      switch (self.platform) {
        case 'android': {
          try {
            Android_Longdo_Map.onCannotGetDetail()
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'ios': {
          try {
            window.webkit.messageHandlers.onCannotGetDetail.postMessage({})
          } catch (e) {
            console.log(e)
          }
          break
        }
        case 'desktop': {
          window.parent.postMessage(['onCannotGetDetail', {}], '*')
          break
        }
        default: {
          self.goToFallbackPage()
        }
      }
    },
    async extractAddress () {
      const self = this
      let reverseGeocodeingResult = null
      let reverseGeocodeingMoo = ''
      let reverseGeocodeingAlley = ''
      let reverseGeocodeingRoad = ''

      if (self.detail.address_th !== '') {
        let result = await self.api.extractAddressRestApi({
          text: self.detail.address_th
        })
        result = result || {}
        if (result.status || null === 200) {
          const { data } = result.data
          if (data[0]) {
            reverseGeocodeingResult = await self.api.getAddress({
              lat: self.detail.location.latitude,
              lon: self.detail.location.longitude,
              locale: 'th'
            })

            reverseGeocodeingMoo = reverseGeocodeingResult.data.village_no || ''
            reverseGeocodeingAlley = reverseGeocodeingResult.data.road_char === 6 ? reverseGeocodeingResult.data.road : ''
            reverseGeocodeingRoad = reverseGeocodeingResult.data.road_char !== 6 ? reverseGeocodeingResult.data.road : ''

            const placeData = data[0] || {}
            self.detail.house_no = self.detail.house_no || placeData.house_no
            self.detail.moo = self.detail.moo || placeData.moo || reverseGeocodeingMoo
            self.detail.village_th = self.detail.village_th || placeData.village.name
            self.detail.building_th = self.detail.building_th || placeData.building.name
            self.detail.floor = self.detail.floor || placeData.floor
            self.detail.room = self.detail.room || placeData.room
            self.detail.alley_th = self.detail.alley_th || placeData.alley.name.trim() || reverseGeocodeingAlley
            self.detail.road_th = self.detail.road_th || placeData.road.name || reverseGeocodeingRoad
            self.detail.country_th = self.detail.country_th || placeData.country.trim()
            self.detail.postcode = self.detail.postcode || placeData.postcode.trim()
            self.detail.subdistrict_th = self.detail.subdistrict_th || placeData.subdistrict.name
            self.detail.district_th = self.detail.district_th || placeData.district.name
            self.detail.province_th = self.detail.province_th || placeData.province.name

            if (self.detail.subdistrict_th === '' && self.detail.district_th === '' && self.detail.province_th === '') {
              if (reverseGeocodeingResult) {
                self.detail.subdistrict_th = reverseGeocodeingResult.data.subdistrict
                self.detail.district_th = reverseGeocodeingResult.data.district
                self.detail.province_th = reverseGeocodeingResult.data.province
                self.detail.postcode = reverseGeocodeingResult.data.postcode
              }
            }

            if (`${self.detail.address_detail_th}`.length === 0 || self.detail.address_detail_th === null) {
              // ต อ จ
              // if (self.detail.province_th.match(/จ\./)) {
              //   self.detail.province_th = self.detail.province_th.replace(/จ\./, '')
              // }

              // if (self.detail.district_th.match(/อ\./)) {
              //   self.detail.district_th = self.detail.district_th.replace(/อ\./, '')
              // }

              // if (self.detail.district_th.includes('เมือง')) {
              //   self.detail.district_th = self.detail.district_th.replace('เมือง', '')
              // }

              // if (self.detail.subdistrict_th.match(/ต\./)) {
              //   self.detail.subdistrict_th = self.detail.subdistrict_th.replace(/ต\./, '')
              // }

              self.detail.address_detail_th = `${self.detail.address_th}`
              self.detail.address_detail_th = self.detail.address_detail_th
                .replace(self.detail.moo, '')
                .replace(self.detail.floor, '')
                .replace(self.detail.name_th, '')
                .replace(self.detail.village_th, '')
                .replace(self.detail.road_th, '')
                .replace(self.detail.building_th, '')
                .replace(self.detail.house_no, '')
                .replace(self.detail.room, '')
                .replace(self.detail.alley_th, '')
                .replace(self.detail.road_th, '')
                .replace(self.detail.province_th, '')
                .replace(self.detail.subdistrict_th, '')
                .replace(self.detail.district_th, '')
                .replace(self.detail.postcode, '')
                .replace(self.detail.country_th, '').trim()
              // Replace more ม. หมู่ ต. อ. จ. ฯ
              self.detail.address_detail_th = self.detail.address_detail_th
                .replace('ถนน', '')
                .replace('ม.', '')
                .replace('ห้อง', '')
                .replace('หมู่ที่', '')
                .replace('หมู่', '')
                .replace('ชั้น', '')
                .replace('ตำบล', '')
                .replace('อำเภอ', '')
                .replace('จังหวัด', '')
                .replace('ต.', '')
                .replace('จ.', '')
                .replace('อ.', '')
                .replace('กิ่ง', '')
                .replace('เมือง', '')
                .replace('คอนโด', '')
                .replace('เลขที่', '')
                .replace('กท', '')
                .trim()
            }
          }
        }
      }

      // extract address en
      if (self.detail.address_en !== '') {
        let result = await self.api.extractAddressRestApi({
          text: self.detail.address_en
        })
        result = result || {}
        if (result.status || null === 200) {
          if (result.status || null === 200) {
            const { data } = result.data
            if (data[0]) {
              reverseGeocodeingResult = await self.api.getAddress({
                lat: self.detail.location.latitude,
                lon: self.detail.location.longitude,
                locale: 'en'
              })

              reverseGeocodeingMoo = reverseGeocodeingResult.data.village_no || ''
              reverseGeocodeingAlley = reverseGeocodeingResult.data.road_char === 6 ? reverseGeocodeingResult.data.road : ''
              reverseGeocodeingRoad = reverseGeocodeingResult.data.road_char !== 6 ? reverseGeocodeingResult.data.road : ''

              const placeData = data[0] || {}
              self.detail.house_no = self.detail.house_no || (placeData.house_no).trim()
              self.detail.moo = self.detail.moo || (placeData.moo).trim() || reverseGeocodeingMoo
              self.detail.village_en = self.detail.village_en || placeData.village.name
              self.detail.building_en = self.detail.building_en || placeData.building.name
              self.detail.floor = self.detail.floor || (placeData.floor).trim()
              self.detail.room = self.detail.room || (placeData.room).trim()
              self.detail.alley_en = self.detail.alley_en || placeData.alley.name.trim() || reverseGeocodeingAlley
              self.detail.road_en = self.detail.road_en || placeData.road.name || reverseGeocodeingRoad
              self.detail.country_en = self.detail.country_en || placeData.country.trim()
              self.detail.postcode = self.detail.postcode || (placeData.postcode).trim()
              self.detail.subdistrict_en = self.detail.subdistrict_en || placeData.subdistrict.name
              self.detail.district_en = self.detail.district_en || placeData.district.name
              self.detail.province_en = self.detail.province_en || placeData.province.name

              if (self.detail.subdistrict_en === '' && self.detail.district_en === '' && self.detail.province_en === '') {
                if (reverseGeocodeingResult) {
                  self.detail.subdistrict_en = reverseGeocodeingResult.data.subdistrict
                  self.detail.district_en = reverseGeocodeingResult.data.district
                  self.detail.province_en = reverseGeocodeingResult.data.province
                  self.detail.postcode = reverseGeocodeingResult.data.postcode
                }
              }

              if (`${self.detail.address_detail_en}`.length === 0 || self.detail.address_detail_en === null) {
                self.detail.address_detail_en = `${self.detail.address_en}`
                self.detail.address_detail_en = self.detail.address_detail_en.replace(self.detail.road_en || placeData.road.name, '')
                  .replace(self.detail.floor, '')
                  .replace(self.detail.road_en, '')
                  .replace(self.detail.building_en, '')
                  .replace(self.detail.moo, '')
                  .replace(self.detail.house_no, '')
                  .replace(self.detail.room, '')
                  .replace(self.detail.alley_en, '')
                  .replace(self.detail.province_en, '')
                  .replace(self.detail.subdistrict_en, '')
                  .replace(self.detail.district_en, '')
                  .replace(self.detail.postcode, '')
                  .replace(self.detail.country_en, '')
                  .replace('Street', '')
                  .replace('King', '')
                  .replace('Room', '')
                  .replace('Moo', '')
                  .replace('n,', '')
                  .replace('th', '')
                  .replace('floor', '')
                  .replace('.', '')
                  .replace(/,/g, '')
                  .trim()
              }
            }
          }
        }
      }

      if (self.detail.country_en === 'Thailand') {
        self.detail.country_th = 'ไทย'
      }

      if (
        self.detail.subdistrict_th !== '' || self.detail.district_th !== '' || self.detail.province_th !== '' ||
        self.detail.subdistrict_en !== '' || self.detail.district_en !== '' || self.detail.province_en !== ''
      ) {
        if (self.detail.country_en === '') {
          self.detail.country_en = 'Thailand'
        }
        if (self.detail.country_th === '') {
          self.detail.country_th = 'ไทย'
        }
      }
    },
    async getPlaceDetail () {
      const self = this
      const result = await self.api.getPlaceDetail({
        id: self.poiId,
        username: self.user.name,
        ldtoken: self.user.longdousertoken
      })
      if (result) {
        if (result.data.status.code === 200) {
          self.detail = result.data.data
        }
      }
    },
    hideBg () {
      document.querySelector('body').style.backgroundColor = 'rgb(255, 255, 255)'
      document.querySelector('#splash-screen-logo').style.opacity = '0'
      document.querySelector('#splash-screen-logo').style.zIndex = '-99'
      document.querySelector('#splash-screen-logo').style.top = '50%'
      document.querySelector('#splash-screen-bg').style.opacity = '0'
      document.querySelector('#splash-screen-bg').style.zIndex = '-99'
      document.querySelector('#splash-screen-bg').style.animation = 'unset'
    }
  },
  beforeDestroy () {
    const self = this
    self.hideBg()
    const closeButton = document.querySelector('.pp-longdocom .pp_close')
    if (closeButton) {
      closeButton.click()
    }
    self.$root.$off('loginComplete', self.onLoginComplete)
    self.$root.$off('loginFormClose', self.onLoginFormClose)
  }
}
</script>

<style lang="scss" scoped>
.edit-place {
  width: 100%;
  height: 100%;
  background-color: transparent;
  .create-place{
    .form{
       margin-bottom: 10px;
    }
  }
}
</style>
