<template>
  <div class="tel-input">
    <div class="preview">
      <div v-if="type.value === 'L'" class="type" :style="{backgroundImage: 'url(\'' + baseUrl + 'img/line_logo.svg?version=' + appVersion + '\')'}"></div>
      <i v-else class="material-icons-round type">{{ type.icon }}</i>
      <div class="summary">
        <div>{{ summary }}</div>
        <div class="remark">{{ remark }}</div>
      </div>
      <button class="required-verification" v-if="showRequiredVerification" @click="clickRequiredVerification">
        <img alt="verify" :src="`${baseUrl}img/required_verification.svg`">
        <div class="guide">กดยืนยันหมายเลข</div>
      </button>
      <button class="ripple confirm-verification" v-if="showVerified">
        <img :src="`${baseUrl}img/confirm_verification.svg`">
      </button>
      <button class="ripple delete" @click="$emit('delete')">
        <i class="material-icons-round">delete</i>
      </button>
      <button class="ripple edit" :class="{active: isEditMode}" @click="isEditMode = !isEditMode">
        <i class="material-icons-round">edit</i>
      </button>
    </div>
    <div class="form" v-show="isEditMode">
      <div class="field">
        <label>{{ $t('telInput.type') }} *</label>
        <DropdownWidget v-model="type" :options="telType" :isRequired="true" :hasFilter="false" />
      </div>
      <div class="multi-field-in-row">
        <div class="field" style="width: calc((100% - 12px) / 3);" v-if="type.value !== 'S' && type.value !== 'L'">
          <label>{{ $t('telInput.countryCode') }}</label>
          <div style="display: flex; flex-direction: row; align-items: center; width: 100%;">
            <div style="padding: 0px 6px 0px 0px; font-size: 16px; padding-top: 6px;">+</div>
            <input type="number" step="any" class="no-spin-button" v-model="countryCode" style="width: 100%;">
          </div>
        </div>
        <!-- <div class="field" style="width: calc((100% - 12px) / 2);">
          <label>{{ $t('telInput.areaCode') }}</label>
          <input type="number" step="any" class="no-spin-button" v-model="areaCode">
        </div> -->
        <div class="field" :style="type.value === 'S' ? 'width: 100%;' : 'width: calc((100% - 12px) * 2 / 3);'" v-if="type.value !== 'L'">
          <label>{{ $t('telInput.telNo') }}</label>
          <input type="number" maxlength="20" step="any" class="no-spin-button" v-model="telNo" >
        </div>
      </div>
      <div class="multi-field-in-row" v-if="type.value !== 'L'">
        <div class="field" style="width: calc((100% - 12px) / 3);" v-if="type.value !== 'M'">
          <label>{{ $t('telInput.telNoTo') }}</label>
          <input type="number" step="any" class="no-spin-button" v-model="telNoTo">
        </div>
        <div class="field" style="width: calc((100% - 12px) / 3); margin: 0px auto 0px 12px;" v-if="type.value !== 'M'">
          <label>{{ $t('telInput.ext') }}</label>
          <input type="text" step="any" class="no-spin-button" v-model="ext">
        </div>
      </div>
      <div class="field" v-if="type.value === 'L'">
        <label>LINE ID</label>
        <TextFieldSuggestWidget v-model="lineId" />
      </div>
      <div class="field" v-if="type.value === 'L'">
        <label>{{ $t('telInput.orLink') }}</label>
        <div style="display: flex; flex-direction: row; align-items: center; width: 100%;">
          <div style="padding: 0px 6px 0px 0px; font-size: 16px; margin-top: 6px;">https://line.me/ti/p/</div>
          <TextFieldSuggestWidget v-model="lineLink" style="width: 100%;" />
        </div>
      </div>
      <div class="field">
        <label>{{ $t('telInput.remark') }}</label>
        <textarea rows="2" v-model="remark"></textarea>
        <span class="guide">{{ $t('telInput.guideRemark') }}</span>
      </div>
      <div class="field">
        <button class="ripple" @click="isEditMode = false" style="margin-left: auto;">{{ $t('telInput.collapse') }}</button>
      </div>
    </div>
    <VerificationDialog
      v-model="showVerificationDialog"
      :tel-number="mobileNumber"
      :userData="userData"
      @close="onCloseVerificationDialog"
      @success="onSuccessVerification"
    />
  </div>
</template>

<script>
import DropdownWidget from '@/components/DropdownWidget.vue'
import TextFieldSuggestWidget from '@/components/TextFieldSuggestWidget.vue'
import VerificationDialog from '@/components/VerificationDialog.vue'

export default {
  name: 'TelInput',
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userData: {
      type: Object,
      default: null
    }
  },
  components: {
    DropdownWidget,
    TextFieldSuggestWidget,
    VerificationDialog
  },
  computed: {
    summary () {
      const self = this
      const temp = []

      if (self.type.value === 'S') {
        if (self.telNo) {
          temp.push(self.telNo)
        }
        if (self.telNoTo) {
          temp.push(`-${self.telNoTo}`)
        }
        if (self.ext) {
          temp.push(self.$t('telInput.ext'))
          temp.push(self.ext)
        }
        return temp.join(' ')
      }

      if (self.type.value === 'L') {
        if (self.lineId && !self.lineLink) {
          temp.push(self.lineId)
        }
        if (self.lineLink) {
          temp.push(`https://line.me/ti/p/${self.lineLink}`)
        }
        return temp.join(' ')
      }

      if (self.countryCode) {
        temp.push(`+${self.countryCode}`)
      }
      if (self.areaCode) {
        temp.push(self.areaCode)
      }
      if (self.telNo && !self.telNoTo) {
        temp.push(self.telNo)
      }
      if (self.telNo && self.telNoTo) {
        temp.push(`${self.telNo}-${self.telNoTo}`)
      }
      if (self.ext) {
        temp.push(self.$t('telInput.ext'))
        temp.push(self.ext)
      }
      return temp.join(' ')
    },
    telType () {
      const self = this
      return [
        {
          value: 'T',
          label: self.$t('telInput.telType.t', 'th'),
          labelEn: self.$t('telInput.telType.t', 'en'),
          icon: 'phone'
        },
        {
          value: 'M',
          label: self.$t('telInput.telType.m', 'th'),
          labelEn: self.$t('telInput.telType.m', 'en'),
          icon: 'phone_iphone'
        },
        {
          value: 'F',
          label: self.$t('telInput.telType.f', 'th'),
          labelEn: self.$t('telInput.telType.f', 'en'),
          icon: 'print'
        },
        {
          value: 'S',
          label: self.$t('telInput.telType.s', 'th'),
          labelEn: self.$t('telInput.telType.s', 'en'),
          icon: 'support_agent'
        },
        {
          value: 'L',
          label: self.$t('telInput.telType.l', 'th'),
          labelEn: self.$t('telInput.telType.l', 'en')
        }
      ]
    },
    mobileNumber () {
      const self = this
      const telNumber = `${self.areaCode}${self.telNo}`
      if (telNumber.length > 0) {
        if (telNumber[0] !== '0') {
          return `0${telNumber}`
        }
      }
      return telNumber
    },
    isMobileNumber () {
      const self = this
      if (self.type.value === 'S' || self.type.value === 'L') {
        return false
      }
      if (self.countryCode !== '66' && self.countryCode !== '') {
        return false
      }
      if (self.mobileNumber === '026329700') {
        return true
      }
      const rex = /^(\+66|66|0)(6|8|9)\d{8}?$/g
      return rex.test(self.mobileNumber)
    },
    showRequiredVerification () {
      const self = this
      return self.isMobileNumber && self.isRequiredVerification && !self.isVerified
    },
    showVerified () {
      const self = this
      return self.isMobileNumber && self.isRequiredVerification && self.isVerified
    },
    isValid () {
      const self = this
      return !self.showRequiredVerification
    }
  },
  data () {
    return {
      user: null,
      isEditMode: true,
      type: {
        value: 'T'
      },
      countryCode: '66',
      areaCode: '',
      telNo: '',
      telNoTo: '',
      ext: '',
      remark: '',
      lineId: '',
      lineLink: '',
      isRequiredVerification: false,
      isVerified: false,
      showVerificationDialog: false,
      checkPhoneNoStatusTask: null
    }
  },
  mounted () {
    const self = this
    self.user = self.userData
    self.$root.$on('loginComplete', self.onLoginComplete)
    self.type.value = self.value.type || self.type.value
    self.countryCode = self.value.countryCode || self.countryCode
    self.areaCode = self.value.areaCode || self.areaCode
    self.telNo = self.value.telNo || self.telNo
    self.telNoTo = self.value.telNoTo || self.telNoTo
    self.ext = self.value.ext || self.ext
    self.remark = self.value.remark || self.remark
    self.lineId = self.value.lineId || self.lineId
    self.lineLink = self.value.lineLink || self.lineLink
    self.isEditMode = !(self.type.value !== 'L' && self.telNo) && !(self.type.value === 'L' && (self.lineId || self.lineLink))
  },
  methods: {
    onLoginComplete (user) {
      const self = this
      user.username = user.name
      self.user = user
    },
    async checkPhoneNoStatus () {
      const self = this
      const params = {}
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.user
      if (userSession) {
        if (userSession.longdousertoken !== '' && userSession.longdousertoken !== null && userSession.longdousertoken !== undefined) {
          params.username = userSession.name || userSession.username
          params.ldtoken = userSession.longdousertoken
        }
      }
      params.phone_no = self.mobileNumber
      const result = await self.api.checkPhoneNoStatus(params)
      self.isRequiredVerification = result.data.data.required || false
      self.isVerified = result.data.data.verified || false
    },
    clickRequiredVerification () {
      const self = this
      self.showVerificationDialog = true
    },
    onCloseVerificationDialog () {
      const self = this
      self.showVerificationDialog = false
    },
    onSuccessVerification () {
      const self = this
      self.isVerified = true
    }
  },
  watch: {
    isMobileNumber: {
      immediate: true,
      handler (newValue) {
        const self = this
        self.isRequiredVerification = false
        self.isVerified = false
        if (newValue) {
          if (self.checkPhoneNoStatusTask) {
            clearInterval(self.checkPhoneNoStatusTask)
          }
          self.checkPhoneNoStatusTask = setTimeout(() => {
            self.$nextTick(() => {
              self.checkPhoneNoStatus()
            })
          }, 800)
        }
      }
    },
    'type.value' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.type = self.type.value
        self.$emit('input', temp)
      })
    },
    'countryCode' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.countryCode = self.countryCode
        self.$emit('input', temp)
      })
    },
    'areaCode' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.areaCode = self.areaCode
        self.$emit('input', temp)
      })
    },
    'telNo' () {
      const self = this
      if (self.telNo.length >= 10 && self.countryCode === '66') {
        self.telNo = self.telNo.substring(0, 10)
      }
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.telNo = self.telNo
        temp.isValid = self.isValid
        self.$emit('input', temp)
      })
    },
    'telNoTo' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.telNoTo = self.telNoTo
        self.$emit('input', temp)
      })
    },
    'ext' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.ext = self.ext
        self.$emit('input', temp)
      })
    },
    'remark' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.remark = self.remark
        self.$emit('input', temp)
      })
    },
    'lineId' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.lineId = self.lineId
        self.$emit('input', temp)
      })
    },
    'lineLink' () {
      const self = this
      self.$nextTick(() => {
        const temp = JSON.parse(JSON.stringify(self.value))
        temp.lineLink = self.lineLink
        self.$emit('input', temp)
      })
    },
    isValid: {
      immediate: true,
      handler () {
        const self = this
        self.$nextTick(() => {
          setTimeout(() => {
            const temp = JSON.parse(JSON.stringify(self.value))
            temp.isValid = self.isValid
            self.$emit('input', temp)
          }, 100)
        })
      }
    },
    'value' () {
      const self = this
      self.$nextTick(() => {
        self.type.value = self.value.type || self.type.value
        self.countryCode = self.value.countryCode || self.countryCode
        self.areaCode = self.value.areaCode || self.areaCode
        self.telNo = self.value.telNo || self.telNo
        self.telNoTo = self.value.telNoTo || self.telNoTo
        self.ext = self.value.ext || self.ext
        self.remark = self.value.remark || self.remark
        self.lineId = self.value.lineId || self.lineId
        self.lineLink = self.value.lineLink || self.lineLink
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tel-input {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;

  .preview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 12px 0px 12px 6px;
    box-sizing: border-box;

    .type {
      font-size: 24px;
      color: $primary;
      margin-right: 6px;
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: calc(100% - 2px);
      flex: 0 0 24px;
    }

    .summary {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 0 1 100%;
      margin-right: auto;
      width: calc(100% - 24px - 6px - 12px - 28px - 12px - 28px);

      div {
        font-size: 16px;
        line-height: 1.5;
        word-break: break-all;
        width: 100%;
      }

      .remark {
        font-size: 12px;
        color: $lightGrey;
        word-break: break-word;
      }
    }

    button {
      margin-left: 12px;
      padding: 6px;
      border-radius: 14px;
      width: 28px;
      height: 28px;
      padding: 4px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid $primary;
      box-sizing: border-box;
      transition: background-color 0.3s ease 0s;
      flex: 0 0 28px;

      &.active {
        background-color: $primary;

        i {
          font-size: 20px;
          color: white;
        }
      }

      i {
        font-size: 18px;
        color: $primary;
      }

      &.confirm-verification,
      &.required-verification {
        border: none;
        padding: 2px;
        border-radius: 0px;
        position: relative;
      }

      &.confirm-verification {
        cursor: default;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .guide {
        position: absolute;
        top: -57px;
        left: -12px;
        transform: translateY(100%);
        color: white;
        z-index: 1;
        background-color: rgb(209, 11, 15);
        padding: 3px 8px;
        border-radius: 4px!important;
        font-size: 12px;
        font-family: "Prompt", serif;
        box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
        width: max-content;

        &:after {
          z-index: -99;
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          bottom: 0;
          top: 100%;
          left: 18px;
          box-sizing: border-box;
          border: 6px solid rgb(209, 11, 15);
          border-color: transparent transparent rgb(209, 11, 15) rgb(209, 11, 15);
          transform-origin: 0 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .form {
    width: 100%;
    padding: 0px 12px 12px 12px;
    box-sizing: border-box;
    background-color: rgba(175, 220, 255, 0.1);
    border-radius: 12px;

    .field {
      display: flex;
      flex-direction: column;
      padding: 12px 0px 0px 0px;
      box-sizing: border-box;

      label {
        font-family: "Prompt",serif;
        font-weight: bold;
        font-size: 16px;
      }

      input {
        outline: none;
        font-size: 16px;
        padding: 0px 12px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: 100%;
        height: 42px;
        line-height: 42px;
        appearance: none;
        margin-top: 6px;

        &::placeholder {
          color: #afafaf;
          font-family: "Prompt",serif;
        }
      }

      textarea {
        outline: none;
        font-size: 16px;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: 100%;
        min-height: 42px;
        line-height: 21px;
        appearance: none;
        resize: none;
        margin-top: 6px;

        &::placeholder {
          color: #afafaf;
          font-family: "Prompt",serif;
        }
      }

      .guide {
        color: #afafaf;
        font-size: 12px;
        line-height: 1.5;
        padding-top: 3px;
      }
    }

    .multi-field-in-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .field {
        width: 50%;
      }
    }

    button {
      height: 28px;
      background-color: $primary;
      padding: 0 12px;
      border-radius: 14px;
      color: white;
      border: none;
      font-family: "Prompt",serif;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
