<template>
  <div class="create-place" v-if="userData" :style="desktopMode ? 'border-radius:10px' : ''">
    <div class="header" v-if="!hideTitleBar">
      <div class="title">{{ title }}</div>
      <button class="close ripple" @click="onClickClose">
        <i class="material-icons-round">close</i>
      </button>
    </div>
    <div class="form" :class="{'hide-title-bar': hideTitleBar}">
      <div class="longdo-map-container">
        <button class="ripple base-map-layer normal" :title="$t('createPlace.normalMap')" v-if="isSatellite" @click="onClickNormalLayer">
          <i class="material-icons-round">satellite_alt</i>
        </button>
        <button class="ripple base-map-layer satellite off" :title="$t('createPlace.satelliteMap')" v-if="!isSatellite" @click="onClickSatelliteLayer">
          <i class="material-icons-round">satellite_alt</i>
        </button>
        <button class="ripple current-location" :title="$t('createPlace.currentLocation')" @click="onClickSelectCurrentLocation">
          <i class="material-icons-round">{{ isGeolocationMobileAppDisabled ? 'location_disabled' : 'my_location' }}</i>
        </button>
        <button class="ripple off" :title="$t('createPlace.lock')" v-if="!isLockLocation" @click="onClickLockMarker">
          <i class="material-icons-round">lock_open</i>
        </button>
        <button class="ripple" :title="$t('createPlace.unlock')" v-if="isLockLocation" @click="onClickUnlockMarker">
          <i class="material-icons-round">lock</i>
        </button>
        <img
          class="marker-crosshair"
          :style="markerCrosshair.style"
          :src="markerCrosshair.src"
          v-if="!isLockLocation"
        >
        <longdo-map @load="loadedLongdoMap"/>
        <div class="location">
          {{ location.lat }}, {{ location.lon }}
        </div>
      </div>

      <div class="admin-section" v-if="isAdmin && isEditMode">
        <div class="multi-field-in-row">
          <div class="field">
            <label>{{ $t('createPlace.private') }} *</label>
            <DropdownWidget v-model="isPrivate" :options="privateList" :isRequired="true" />
          </div>
          <div class="field">
            <label>{{ $t('createPlace.status') }} *</label>
            <DropdownWidget v-model="status" :options="statusList" :isRequired="true" />
          </div>
          <div class="field">
            <label>{{ $t('createPlace.locked') }} *</label>
            <DropdownWidget v-model="locked" :options="lockedList" :isRequired="true" />
          </div>
        </div>
      </div>

      <div class="multi-field-in-row">
        <div class="field latitude">
          <label>latitude *</label>
          <TextFieldSuggestWidget placeholder="latitude" :onChange="onChangeLatLon" v-model="location.lat" :invalid="!isValidLat"/>
        </div>
        <div class="field longitude">
          <label>longitude *</label>
          <TextFieldSuggestWidget placeholder="longitude" :onChange="onChangeLatLon" v-model="location.lon" :invalid="!isValidLon"/>
        </div>
        <div class="field category">
          <label>{{ $t('createPlace.category') }} *</label>
          <DropdownWidget v-model="type" :options="typeList" :isRequired="true" />
        </div>
      </div>

      <div class="multi-field-in-row">
        <div class="field place-name-th" v-show="isShowPlaceNameInput">
          <label>{{ $t('createPlace.placeName') + $t('createPlace.placeNameThPlaceholder') }} *</label>
          <TextFieldSuggestWidget :invalid="isValidPlaceName.th === false" v-model="placeName.th" />
        </div>
        <div class="field place-name-en" v-show="isShowPlaceNameInput">
          <label>{{ $t('createPlace.placeName') + $t('createPlace.placeNameEnPlaceholder') }} *</label>
          <TextFieldSuggestWidget :invalid="isValidPlaceName.en === false" v-model="placeName.en" />
        </div>
      </div>

      <div class="address-section">
        <div class="multi-field-in-row">
          <div class="field house-no">
            <label>{{ $t('createPlace.houseNo') }}</label>
            <TextFieldSuggestWidget v-model="houseNo"  :invalid="isValidHouseNo === false"/>
          </div>
          <div class="field moo" v-show="isThailand">
            <label>{{ $t('createPlace.moo') }}</label>
            <TextFieldSuggestWidget v-model="moo" :invalid="isValidMoo === false"/>
          </div>
          <div class="field village">
            <label>{{ $t('createPlace.village') }}</label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidVillageName.th === false" :placeholder="$t('createPlace.villageThPlaceholder')" v-model="village.th" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidVillageName.en === false" :placeholder="$t('createPlace.villageEnPlaceholder')" v-model="village.en" />
          </div>
        </div>

        <div class="multi-field-in-row">
          <div class="field condo">
            <label>{{ $t('createPlace.condo') }}</label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidCondoName.th === false" :placeholder="$t('createPlace.condoThPlaceholder')" v-model="condo.th" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidCondoName.en === false" :placeholder="$t('createPlace.condoEnPlaceholder')" v-model="condo.en" />
          </div>
          <div class="field building">
            <label>{{ $t('createPlace.building') }}</label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidBuildingName.th === false" :placeholder="$t('createPlace.buildingThPlaceholder')" v-model="building.th" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidBuildingName.en === false" :placeholder="$t('createPlace.buildingEnPlaceholder')" v-model="building.en" />
          </div>
          <div class="field floor">
            <label>{{ $t('createPlace.floor') }}</label>
            <TextFieldSuggestWidget v-model="floor"/>
          </div>
          <div class="field room-no">
            <label>{{ $t('createPlace.roomNo') }}</label>
            <TextFieldSuggestWidget v-model="roomNo"/>
          </div>
        </div>

        <div class="multi-field-in-row">
          <div class="field" v-show="isThailand">
            <label>{{ $t('createPlace.street') }}</label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :placeholder="$t('createPlace.streetThPlaceholder')" v-model="street.th" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :placeholder="$t('createPlace.streetEnPlaceholder')" v-model="street.en" />
          </div>
          <div class="field">
            <label>{{ $t('createPlace.road') }}</label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :placeholder="$t('createPlace.roadThPlaceholder')" v-model="road.th" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :placeholder="$t('createPlace.roadEnPlaceholder')" v-model="road.en" />
          </div>
        </div>

        <div class="multi-field-in-row">
          <div class="field">
            <label>{{ subdistrictFieldLabel }} <span v-if="isThailand">*</span></label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidSubdistrict.th === false" :placeholder="$t('createPlace.subdistrictThPlaceholder')" v-model="subdistrict.th" :suggests="subdistrictNameThSuggestList" @select="onSelectSubdistrictThSuggest" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidSubdistrict.en === false" :placeholder="$t('createPlace.subdistrictEnPlaceholder')" v-model="subdistrict.en" :suggests="subdistrictNameEnSuggestList" @select="onSelectSubdistrictEnSuggest" />
          </div>
          <div class="field">
            <label>{{ districtFieldLabel }} <span v-if="isThailand">*</span></label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidDistrict.th === false" :placeholder="$t('createPlace.districtThPlaceholder')" v-model="district.th" :suggests="districtNameThSuggestList" @select="onSelectDistrictThSuggest" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidDistrict.en === false" :placeholder="$t('createPlace.districtEnPlaceholder')" v-model="district.en" :suggests="districtNameEnSuggestList" @select="onSelectDistrictEnSuggest" />
          </div>
        </div>

        <div class="multi-field-in-row">
          <div class="field">
            <label>{{ $t('createPlace.province') }} <span v-if="isThailand">*</span></label>
            <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidProvince.th === false" :placeholder="$t('createPlace.provinceThPlaceholder')" v-model="province.th" :suggests="provinceNameThSuggestList" @select="onSelectProvinceThSuggest" />
            <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidProvince.en === false" :placeholder="$t('createPlace.provinceEnPlaceholder')" v-model="province.en" :suggests="provinceNameEnSuggestList" @select="onSelectProvinceEnSuggest" />
          </div>
          <div class="field">
            <label>{{ $t('createPlace.postcode') }} <span v-if="isThailand">*</span></label>
            <TextFieldSuggestWidget :invalid="isValidPostcode === false" v-model="postcode" />
          </div>
        </div>

        <div class="field">
          <label>{{ $t('createPlace.description') }}</label>
          <span>{{ previewAddressDetail }}</span>
          <textarea rows="2" v-show="langMode === 'th'" :placeholder="$t('createPlace.descriptionThPlaceholder')" v-model="description.th"></textarea>
          <textarea rows="2" v-show="langMode === 'en'" :placeholder="$t('createPlace.descriptionEnPlaceholder')" v-model="description.en"></textarea>
        </div>

        <div class="field">
          <label>{{ $t('createPlace.country') }} *</label>
          <TextFieldSuggestWidget v-show="langMode === 'th'" :invalid="isValidCountry.th === false" :placeholder="$t('createPlace.countryThPlaceholder')" v-model="country.th" :suggests="countryNameThSuggestList" @select="onSelectCountryThSuggest" />
          <TextFieldSuggestWidget v-show="langMode === 'en'" :invalid="isValidCountry.en === false" :placeholder="$t('createPlace.countryEnPlaceholder')" v-model="country.en" :suggests="countryNameEnSuggestList" @select="onSelectCountryEnSuggest" />
        </div>

        <div class="field">
          <button class="ripple" @click="clickChangeLangMode">{{ $t(`createPlace.changeLangeMode.${langMode}`) }}</button>
        </div>
      </div>

      <button id="more-info" class="ripple add-more" @click="showMore = !showMore">
        <i class="material-icons-round">{{ showMore ? 'expand_less' : 'expand_more' }}</i>
        {{ $t('createPlace.add-more') }}
      </button>

      <div v-show="showMore">
        <div class="field" v-show="isAdmin && isThailand && isShowParentListInput">
          <label>{{ $t('createPlace.parentPlace') }}</label>
          <DropdownWidget v-model="parent" :options="parentList" />
        </div>

        <div class="field">
          <label>{{ $t('createPlace.tag') }}</label>
          <TextFieldSuggestWidget v-model="tags" :suggests="tagSuggestList" @select="onSelectTagSuggest" />
          <span class="guide">{{ $t('createPlace.guideTag') }}</span>
        </div>

        <div class="field">
          <label>{{ $t('createPlace.contact') }} ({{ telList.length }})</label>
          <span class="warning" v-if="isRepeatedTel"><i class="material-icons-round">warning</i>{{ $t('createPlace.repeatedTel') }}</span>
          <TelInput v-for="(tel, index) in telList" :key="`tel-${tel.id}`" v-model="telList[index]" :userData="userData" @delete="deleteContact(index)" />
        </div>

        <button class="ripple add-contact" @click="addTel">
          <i class="material-icons-round">add</i>
          {{ $t('createPlace.addContact') }}
        </button>

        <div class="field">
          <label>{{ $t('createPlace.website') }}</label>
          <TextFieldSuggestWidget v-model="websiteUrl" />
        </div>

        <div class="field full-description" v-if="!hideFullDescription">
          <label>{{ $t('createPlace.fullDescriptionTh') }}</label>
          <VueEditor v-model="fullDescription.th" :editorToolbar="customToolbar" />
        </div>

        <div class="field full-description" v-if="!hideFullDescription">
          <label>{{ $t('createPlace.fullDescriptionEn') }}</label>
          <VueEditor v-model="fullDescription.en" :editorToolbar="customToolbar" />
        </div>
        <div class="field">
          <label>{{ $t('createPlace.workingHours') }}</label>
          <div class="day" v-for="(day, index) in dayList" :key="day.name">
            <label class="name">
              <input type="checkbox" v-model="day.selected" @input="onChangeDayCheckbox(index)">
              {{ $t(`day.${day.name}`) }}
            </label>
            <div v-if="day.selected">
              <div class="time start">
                <label class="topic">{{ $t('createPlace.workingHoursOpen') }}</label>
                <DropdownWidget class="hour" v-model="day.startHour" :isRequired="true" :options="hourList" :disabled="!day.selected || day.selectedAllDay" />
                <label>:</label>
                <DropdownWidget class="min" v-model="day.startMin" :isRequired="true" :options="minList" :disabled="!day.selected || day.selectedAllDay" />
              </div>
              <div class="time end">
                <label class="topic">{{ $t('createPlace.workingHoursClose') }}</label>
                <DropdownWidget class="hour" v-model="day.endHour" :isRequired="true" :options="hourList" :disabled="!day.selected || day.selectedAllDay" />
                <label>:</label>
                <DropdownWidget class="min" v-model="day.endMin" :isRequired="true" :options="minList" :disabled="!day.selected || day.selectedAllDay" />
              </div>
              <div class="time">
                <label class="all-day">
                  <input type="checkbox" v-model="day.selectedAllDay" :disabled="!day.selected" @input="onChangeAllDayCheckbox(index)">
                  {{ $t('createPlace.allDay') }}
                </label>
              </div>
            </div>

          </div>
          <label class="topic">{{ $t('createPlace.workingHoursRemark') }}</label>
          <TextFieldSuggestWidget v-model="workingHoursRemark" />
        </div>

        <div class="field" v-show="!isEditMode || true">
          <label>{{ $t('createPlace.image') }} ({{ imageList.length }})</label>
          <div ref="preview-image-list" class="preview-image-list">
            <ImageWidget
              v-for="(image, index) in imageList"
              :key="`image-${index}`"
              :image="image"
              :width="previewImageSize"
              :height="previewImageSize"
              :canEdit="image.file !== null"
              :canDelete="image.file !== null"
              @delete="onDeleteImage(index)"
              :style="{
                marginRight: (index + 1) % previewImagePerRow === 0 ? '0' : `${previewImageGap}px`,
                marginBottom: `${previewImageGap}px`
              }"
            />
            <button
              class="add-image ripple"
              :style="{width: previewImageSize, height: previewImageSize}"
              @click="onClickAddImage"
            >
              <i class="material-icons-round">add_photo_alternate</i>
              {{ $t('createPlace.addImage') }}
            </button>
            <input ref="add-image-input" class="add-image-input" type="file" accept="image/*" @change="manageImageList()" v-show="false" multiple>
          </div>
        </div>
      </div>

      <!-- <div class="field warning-privacy">{{ $t('createPlace.warningPrivacy') }}</div> -->
    </div>
    <div class="action">
      <button class="ripple" @click="beforeSave()" :disabled="!isValid || isSavingPlace">{{ saveButtonLabel }}</button>
    </div>
    <div class="alert-before-save" :style="desktopMode ? 'border-radius:10px' : ''" v-if="isShowAlertBeforeSave">
      <div class="dialog-card">
        <div class="msg">
          <i class="material-icons-round">warning</i>
          <br>
          <span v-html="$t('createPlace.alertBeforeSave.msg')"></span>
        </div>
        <div class="action">
          <button class="ripple outline" @click="isShowAlertBeforeSave = false">
            {{ $t('createPlace.alertBeforeSave.edit') }}
          </button>
          <button class="ripple" @click="savePlace" :disabled="countDownConfirm > 0">
            {{ $t('createPlace.alertBeforeSave.confirm') }} {{ countDownConfirm > 0 ? `(${countDownConfirm})` : ''}}
          </button>
        </div>
      </div>
    </div>
    <div class="loading-overlay" v-if="isSavingPlace">
      <img src="@/assets/img/loading.gif" v-if="createErrorMessage === '' && createSuccessMessage === ''">
      <span class="error-message" v-if="createErrorMessage !== ''">{{ createErrorMessage }}</span>
      <span class="success-message" v-if="createSuccessMessage !== ''">{{ createSuccessMessage }}</span>
    </div>
  </div>
</template>

<script>
import DropdownWidget from '@/components/DropdownWidget.vue'
import TextFieldSuggestWidget from '@/components/TextFieldSuggestWidget.vue'
import TelInput from '@/components/TelInput.vue'
import ImageWidget from '@/components/ImageWidget.vue'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CreatePlace',
  components: {
    DropdownWidget,
    TextFieldSuggestWidget,
    TelInput,
    ImageWidget,
    VueEditor
  },
  props: {
    desktopMode: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      default: null
    },
    detail: {
      type: Object,
      default: null
    },
    hideTitleBar: {
      type: Boolean,
      default: false
    },
    initialLocation: {
      type: Object,
      default: null
    },
    initialZoom: {
      type: Number,
      default: null
    },
    hideFullDescription: {
      type: Boolean,
      default: false
    },
    isGeolocationMobileAppDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isEditMode () {
      const self = this
      return self.detail !== null
    },
    title () {
      const self = this
      return self.isEditMode ? self.$t('createPlace.editTitle') : self.$t('createPlace.title')
    },
    saveButtonLabel () {
      const self = this
      return self.isEditMode ? self.$t('createPlace.save') : self.$t('createPlace.submit')
    },
    markerCrosshair () {
      return {
        style: {
          width: `${process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH}px`,
          height: `${process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT}px`
        },
        src: process.env.VUE_APP_LONGDO_MAP_PIN
      }
    },
    isValid () {
      const self = this
      let isValid = true
      isValid = isValid && self.isValidLat
      isValid = isValid && self.isValidLon
      isValid = isValid && (self.isValidPlaceName.th === true)
      isValid = isValid && (self.isValidPlaceName.en === true)
      isValid = isValid && (self.isValidVillageName.th === true || self.isValidVillageName.th === null)
      isValid = isValid && (self.isValidVillageName.en === true || self.isValidVillageName.en === null)
      isValid = isValid && (self.isValidCondoName.th === true || self.isValidCondoName.th === null)
      isValid = isValid && (self.isValidCondoName.en === true || self.isValidCondoName.en === null)
      isValid = isValid && (self.isValidBuildingName.th === true || self.isValidBuildingName.th === null)
      if (self.isThailand) {
        isValid = isValid && self.isValidHouseNo
        isValid = isValid && self.isValidMoo
        isValid = isValid && self.isValidSubdistrict.th
        isValid = isValid && self.isValidSubdistrict.en
        isValid = isValid && self.isValidDistrict.th
        isValid = isValid && self.isValidDistrict.en
        isValid = isValid && self.isValidProvince.th
        isValid = isValid && self.isValidProvince.en
        isValid = isValid && self.isValidPostcode
      }
      isValid = isValid && self.isValidCountry.th
      isValid = isValid && self.isValidCountry.en
      // admin can ignore tel verification
      isValid = isValid && (self.telList.filter(t => !(t.isValid || self.isAdmin)).length <= 0)
      isValid = isValid && !self.isRepeatedTel
      if (!(self.type.value || false)) {
        isValid = false
      }
      return isValid
    },
    isRepeatedTel () {
      const self = this
      let isRepeatedTel = false
      for (let i = 0; i < self.telList.length - 1; i++) {
        for (let j = i + 1; j < self.telList.length; j++) {
          if (self.telList[i].areaCode === self.telList[j].areaCode &&
            self.telList[i].countryCode === self.telList[j].countryCode &&
            self.telList[i].ext === self.telList[j].ext &&
            self.telList[i].lineId === self.telList[j].lineId &&
            self.telList[i].lineLink === self.telList[j].lineLink &&
            self.telList[i].telNo === self.telList[j].telNo &&
            self.telList[i].telNoTo === self.telList[j].telNoTo &&
            self.telList[i].telNoTo === self.telList[j].telNoTo &&
            !((self.telList[i].type === 'T' && self.telList[j].type === 'F') || (self.telList[i].type === 'F' && self.telList[j].type === 'T'))
          ) {
            isRepeatedTel = true
            break
          }
        }
        if (isRepeatedTel) {
          break
        }
      }
      return isRepeatedTel
    },
    previewAddressDetail () {
      const self = this
      let addr = ''

      const hasMooWord = `${self.moo}`.indexOf('หมู่') === 0 || `${self.moo}`.indexOf('ม.') === 0 || `${self.moo}`.indexOf('moo') === 0

      const hasVillageWord = {
        th: `${self.village.th}`.indexOf('หมู่บ้าน') === 0 || `${self.village.th}`.indexOf('มบ.') === 0,
        en: false
      }

      const hasCondoWord = {
        th: `${self.condo.th}`.indexOf('คอนโด') === 0 || `${self.condo.th}`.endsWith('คอนโด') || `${self.condo.th}`.indexOf('อพาร์ทเม้นท์') === 0 || `${self.condo.th}`.endsWith('อพาร์ทเม้นท์'),
        en: false
      }

      const hasBuildingWord = {
        th: `${self.building.th}`.indexOf('ตึก') === 0 || `${self.building.th}`.indexOf('อาคาร') === 0 || `${self.building.th}`.indexOf('อ.') === 0,
        en: `${self.building.en}`.toLowerCase().endsWith('building') || `${self.building.en}`.endsWith('tower')
      }

      const hasFloorWord = `${self.floor}`.indexOf('ชั้น') === 0 || `${self.floor}`.toLowerCase().endsWith('floor') || `${self.floor}`.toLowerCase().endsWith('fl.')

      const hasRoomWord = `${self.roomNo}`.indexOf('ห้อง') === 0 || `${self.roomNo}`.toLowerCase().endsWith('room') || `${self.roomNo}`.toLowerCase().endsWith('rm.')

      const hasSoiWord = {
        th: `${self.street.th}`.indexOf('ซอย') === 0 || `${self.street.th}`.indexOf('ซ.') === 0,
        en: `${self.street.en}`.toLowerCase().indexOf('soi') === 0
      }
      const hasRoadWord = {
        th: `${self.road.th}`.indexOf('ถนน') === 0 || `${self.road.th}`.indexOf('ถ.') === 0,
        en: `${self.road.en}`.toLowerCase().endsWith('road') || `${self.road.en}`.endsWith('rd.')
      }

      const hasSubdistrictWord = {
        th: `${self.subdistrict.th}`.indexOf('แขวง') === 0 || `${self.subdistrict.th}`.indexOf('ตำบล') === 0 || `${self.subdistrict.th}`.indexOf('ต.') === 0,
        en: false
      }

      const hasDistrictWord = {
        th: `${self.district.th}`.indexOf('เขต') === 0 || `${self.district.th}`.indexOf('อำเภอ') === 0 || `${self.district.th}`.indexOf('อ.') === 0,
        en: false
      }

      const hasProvinceWord = {
        th: `${self.province.th}`.indexOf('จังหวัด') === 0 || `${self.province.th}`.indexOf('จ.') === 0,
        en: false
      }

      if (self.langMode === 'th') {
        const isBangkok = `${self.province.th}`.indexOf('กทม') >= 0 || `${self.province.th}`.indexOf('กรุงเทพ') >= 0
        const isThailand = `${self.country.th}`.indexOf('ไทย') >= 0
        const subdistrictPrefix = isThailand ? (isBangkok ? 'แขวง' : 'ต.') : ''
        const districtPrefix = isThailand ? (isBangkok ? 'เขต' : 'อ.') : ''
        const provincePrefix = isThailand ? (isBangkok ? '' : 'จ.') : ''
        const addressParts = [
          self.houseNo ? `เลขที่ ${self.houseNo}` : '',
          self.moo ? `${hasMooWord ? '' : 'หมู่ที่ '}${self.moo}` : '',
          self.village.th ? `${hasVillageWord.th ? '' : 'หมู่บ้าน'}${self.village.th}` : '',
          self.condo.th ? `${hasCondoWord.th ? '' : 'คอนโดมิเนียม'}${self.condo.th}` : '',
          self.building.th ? `${hasBuildingWord.th ? '' : 'อาคาร'}${self.building.th}` : '',
          self.floor ? `${hasFloorWord ? '' : 'ชั้น '}${self.floor}` : '',
          self.roomNo ? `${hasRoomWord ? '' : 'ห้อง '}${self.roomNo}` : '',
          self.street.th ? `${hasSoiWord.th ? '' : 'ซอย'}${self.street.th}` : '',
          self.road.th ? `${hasRoadWord.th ? '' : 'ถนน'}${self.road.th}` : '',
          self.subdistrict.th ? `${hasSubdistrictWord.th ? '' : subdistrictPrefix}${self.subdistrict.th}` : '',
          self.district.th ? `${hasDistrictWord.th ? '' : districtPrefix}${self.district.th}` : '',
          self.province.th ? `${hasProvinceWord.th ? '' : provincePrefix}${self.province.th}` : '',
          self.postcode,
          self.country.th
        ]
        addr = addressParts.filter(Boolean).join(' ')
      } else {
        const addressPartsEN = [
          self.houseNo,
          self.moo ? `Moo ${self.moo}` : '',
          self.village.en,
          self.condo.en,
          self.building.en ? `${self.building.en}${hasBuildingWord.en ? '' : ' Building'}` : '',
          self.floor ? `${self.floor}${hasFloorWord.en ? '' : ' Floor'}` : '',
          self.roomNo ? `${hasRoomWord.en ? '' : 'Room No. '}${self.roomNo}` : '',
          self.street.en ? `${hasSoiWord.en ? '' : 'Soi '}${self.street.en}` : '',
          self.road.en ? `${self.road.en}${hasRoadWord.en ? '' : ' Rd.'}` : '',
          self.subdistrict.en,
          self.district.en,
          self.province.en,
          self.postcode,
          self.country.en
        ]
        addr = addressPartsEN.filter(Boolean).join(', ')
      }
      return addr
    },
    previewImageMinWidth () {
      return 128
    },
    previewImageGap () {
      return 12
    },
    previewImagePerRow () {
      const self = this
      const minWidth = self.previewImageMinWidth
      const gap = self.previewImageGap
      const itemsPerRow = Math.floor((self.previewImageListWidth + gap) / (minWidth + gap))
      return itemsPerRow
    },
    previewImageSize () {
      const self = this
      const gap = self.previewImageGap
      const size = ((self.previewImageListWidth + gap) / self.previewImagePerRow) - gap
      return `${size}px`
    },
    isShowPlaceNameInput () {
      const self = this
      return self.type.value !== '64'
    },
    isShowParentListInput () {
      const self = this
      return self.parentList.length > 0
    },
    subdistrictNameThSuggestList () {
      const self = this
      return self.subdistrictThSuggestList.map(p => p.name)
    },
    subdistrictNameEnSuggestList () {
      const self = this
      return self.subdistrictEnSuggestList.map(p => p.name)
    },
    districtNameThSuggestList () {
      const self = this
      return self.districtThSuggestList.map(p => p.name)
    },
    districtNameEnSuggestList () {
      const self = this
      return self.districtEnSuggestList.map(p => p.name)
    },
    provinceNameThSuggestList () {
      const self = this
      return self.provinceThSuggestList.map(p => p.name)
    },
    provinceNameEnSuggestList () {
      const self = this
      return self.provinceEnSuggestList.map(p => p.name)
    },
    countryNameThSuggestList () {
      const self = this
      return self.countryList.map(c => c.name)
    },
    countryNameEnSuggestList () {
      const self = this
      return self.countryList.map(c => c.nameEn)
    },
    subdistrictFieldLabel () {
      const self = this
      const bkk = ['กรุงเทพ', 'กรุงเทพฯ', 'กรุงเทพมหานคร', 'กทม.', 'Bangkok', 'bangkok', 'bkk', 'BKK', 'Bkk']
      if (bkk.includes(self.province.th) || bkk.includes(self.province.en)) {
        return self.$t('createPlace.khweang')
      }
      return self.$t('createPlace.subdistrict')
    },
    districtFieldLabel () {
      const self = this
      const bkk = ['กรุงเทพ', 'กรุงเทพฯ', 'กรุงเทพมหานคร', 'กทม.', 'Bangkok', 'bangkok', 'bkk', 'BKK', 'Bkk']
      if (bkk.includes(self.province.th) || bkk.includes(self.province.en)) {
        return self.$t('createPlace.khet')
      }
      return self.$t('createPlace.district')
    },
    isThailand () {
      var self = this
      return self.country.th === 'ไทย' || self.country.th === 'ประเทศไทย' || self.country.en === 'Thailand' || self.country.en === 'thailand'
    },
    customToolbar () {
      return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'] // remove formatting button
      ]
    },
    privateList () {
      return [
        {
          value: 'Y',
          label: 'ส่วนตัว',
          labelEn: 'Private'
        },
        {
          value: 'N',
          label: 'สาธารณะ',
          labelEn: 'Public'
        }
      ]
    },
    statusList () {
      return [
        {
          value: 'N',
          label: 'New',
          labelEn: 'New'
        },
        {
          value: 'A',
          label: 'Approved',
          labelEn: 'Approved'
        },
        {
          value: 'R',
          label: 'Rejected',
          labelEn: 'Rejected'
        },
        {
          value: 'D',
          label: 'Deleted',
          labelEn: 'Deleted'
        },
        {
          value: 'O',
          label: 'Obsoleted',
          labelEn: 'Obsoleted'
        }
      ]
    },
    lockedList () {
      return [
        {
          value: 'Y',
          label: 'Locked',
          labelEn: 'Locked'
        },
        {
          value: 'N',
          label: 'Unlocked',
          labelEn: 'Unlocked'
        }
      ]
    },
    isAdmin () {
      const self = this
      const roles = (self.userData || {}).roles || {}
      for (const key in roles) {
        if (roles[key] === 'webmaster') { return true }
      }
      return false
    }
  },
  data () {
    var hourList = []
    for (let h = 0; h < 24; h++) {
      const hour = `${h < 10 ? 0 : ''}${h}`
      hourList.push({
        value: hour,
        label: hour,
        labelEn: hour
      })
    }

    var minList = []
    for (let m = 0; m < 60; m++) {
      const min = `${m < 10 ? 0 : ''}${m}`
      minList.push({
        value: min,
        label: min,
        labelEn: min
      })
    }

    return {
      // user: null,
      map3Ready: false,
      langMode: this.$root.$i18n.locale,
      map: null,
      marker: null,
      location: {
        lat: '',
        lon: ''
      },
      placeName: {
        th: '',
        en: ''
      },
      isPrivate: {
        value: ''
      },
      status: {
        value: ''
      },
      locked: {
        value: ''
      },
      type: {
        value: ''
      },
      typeList: [],
      tags: '',
      tagSuggestList: [],
      getTagSuggestListTask: null,
      websiteUrl: '',
      houseNo: '',
      moo: '',
      floor: '',
      roomNo: '',
      parent: {
        value: ''
      },
      parentList: [],
      village: {
        th: '',
        en: ''
      },
      condo: {
        th: '',
        en: ''
      },
      building: {
        th: '',
        en: ''
      },
      street: {
        th: '',
        en: ''
      },
      road: {
        th: '',
        en: ''
      },
      suggestSubdistrictTask: null,
      subdistrictThSuggestList: [],
      subdistrictEnSuggestList: [],
      subdistrict: {
        th: '',
        en: '',
        code: ''
      },
      suggestDistrictTask: null,
      districtThSuggestList: [],
      districtEnSuggestList: [],
      district: {
        th: '',
        en: '',
        code: ''
      },
      suggestProvinceTask: null,
      provinceThSuggestList: [],
      provinceEnSuggestList: [],
      province: {
        th: '',
        en: '',
        code: ''
      },
      postcode: '',
      description: {
        th: '',
        en: ''
      },
      countryList: [],
      country: {
        th: '',
        en: ''
      },
      updateAddessTask: null,
      telList: [],
      workingHoursRemark: '',
      dayList: [
        {
          name: 'mon',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        },
        {
          name: 'tue',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        },
        {
          name: 'wed',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        },
        {
          name: 'thu',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        },
        {
          name: 'fri',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        },
        {
          name: 'sat',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        },
        {
          name: 'sun',
          selected: false,
          selectedAllDay: false,
          startHour: '09',
          startMin: '00',
          endHour: '18',
          endMin: '00'
        }
      ],
      hourList,
      minList,
      fullDescription: {
        th: '',
        en: ''
      },
      imageList: [],
      previewImageListWidth: 0,
      showMore: false,
      // validation
      isValidLat: true,
      isValidLon: true,
      isValidPlaceName: {
        th: null,
        en: null
      },
      isValidVillageName: {
        th: null,
        en: null
      },
      isValidCondoName: {
        th: null,
        en: null
      },
      isValidBuildingName: {
        th: null,
        en: null
      },
      isValidHouseNo: true,
      isValidMoo: true,
      isValidSubdistrict: {
        th: true,
        en: true
      },
      isValidDistrict: {
        th: true,
        en: true
      },
      isValidProvince: {
        th: true,
        en: true
      },
      isValidPostcode: true,
      isValidCountry: {
        th: true,
        en: true
      },
      isSavingPlace: false,
      createErrorMessage: '',
      createSuccessMessage: '',
      isLockLocation: true,
      dolLayer: null,
      isSatellite: false,
      isShowAlertBeforeSave: false,
      countDownConfirm: 5,
      countDownConfirmTask: null
    }
  },
  created () {
    document.querySelector('body').style.backgroundColor = 'rgb(255, 255, 255)'
    document.querySelector('#splash-screen-logo').style.opacity = '0'
    document.querySelector('#splash-screen-logo').style.zIndex = '-99'
    document.querySelector('#splash-screen-logo').style.top = '50%'
    document.querySelector('#splash-screen-bg').style.opacity = '0'
    document.querySelector('#splash-screen-bg').style.zIndex = '-99'
    document.querySelector('#splash-screen-bg').style.animation = 'unset'
  },
  async mounted () {
    const self = this
    window.addEventListener('resize', self.onWindowResize)
    self.onWindowResize()
    let userSession = await self.utility.getUserSession()
    userSession = userSession || self.userData
    if (!userSession) {
      self.$root.$emit('showLongdoLoginForm')
    }
    if (!self.isEditMode) {
      self.addTel()
    }
    self.getTypeList()
    self.getCountryList()

    if (self.isEditMode) {
      self.placeName = {
        th: self.detail.name_th || '',
        en: self.detail.name_en || ''
      }
      self.isPrivate = {
        value: self.detail.private || ''
      }
      self.status = {
        value: self.detail.status || ''
      }
      self.locked = {
        value: self.detail.locked || ''
      }
      self.type = {
        value: self.detail.iconfile_id || ''
      }
      self.tags = (self.detail.tags || []).join(', ')
      self.websiteUrl = self.detail.website || ''
      self.houseNo = self.detail.house_no || ''
      self.moo = self.detail.moo || ''
      self.floor = self.detail.floor || ''
      self.roomNo = self.detail.room || ''
      self.parent = {
        value: self.detail.parent_ooiid || ''
      }
      self.village = {
        th: self.detail.village_th || '',
        en: self.detail.village_en || ''
      }
      self.condo = {
        th: self.detail.condo_th || '',
        en: self.detail.condo_en || ''
      }
      self.building = {
        th: self.detail.building_th || '',
        en: self.detail.building_en || ''
      }
      self.street = {
        th: self.detail.alley_th || '',
        en: self.detail.alley_en || ''
      }
      self.road = {
        th: self.detail.road_th || '',
        en: self.detail.road_en || ''
      }
      self.subdistrict = {
        th: self.detail.subdistrict_th || '',
        en: self.detail.subdistrict_en || ''
      }
      self.district = {
        th: self.detail.district_th || '',
        en: self.detail.district_en || ''
      }
      self.province = {
        th: self.detail.province_th || '',
        en: self.detail.province_en || ''
      }
      self.postcode = self.detail.postcode || ''
      self.description = {
        th: self.detail.address_detail_th || '',
        en: self.detail.address_detail_en || ''
      }
      self.country = {
        th: self.detail.country_th || '',
        en: self.detail.country_en || ''
      }
      self.telList = self.detail.contact_list.map(c => {
        return {
          id: c.tid || `_${new Date().getTime()}`,
          type: c.type || 'T',
          countryCode: c.country_code || '66',
          areaCode: '', // c.area_code || '',
          telNo: (c.area_code || '') + (c.tel_no || ''),
          telNoTo: c.tel_no_to || '',
          ext: c.ext || '',
          remark: c.remark || '',
          lineId: c.line_id || '',
          lineLink: c.line_link.replace('http://line.me/ti/p/', '') || '',
          isValid: true
        }
      })
      self.detail.images.forEach(image => {
        self.imageList.push({
          file: null,
          original: image.imagepath,
          preview: `${image.imagepath}/256`,
          comment: image.description,
          orientation: -1
        })
      })
      self.fullDescription = {
        th: `${self.detail.description_th}`.replace(/[\n\t\r]/g, '').replace(/\\[a-z]/g, ''),
        en: `${self.detail.description_en}`.replace(/[\n\t\r]/g, '').replace(/\\[a-z]/g, '')
      }
      const workingHours = self.detail.working_hours_list ? self.detail.working_hours_list : {}
      workingHours.hours = workingHours.hours || []
      self.workingHoursRemark = workingHours.remark || ''
      workingHours.hours.forEach(i => {
        const index = self.dayList.map(d => d.name).indexOf(i.day)
        if (index > -1) {
          self.dayList[index].selected = true
          if (i.end === '24:00') {
            self.dayList[index].startHour = {
              value: ''
            }
            self.dayList[index].startMin = {
              value: ''
            }
            self.dayList[index].endHour = {
              value: ''
            }
            self.dayList[index].endMin = {
              value: ''
            }
            self.dayList[index].selectedAllDay = true
          } else {
            const splitedStart = i.start.split(':')
            self.dayList[index].startHour = {
              value: splitedStart[0]
            }
            self.dayList[index].startMin = {
              value: splitedStart[1]
            }
            const splitedEnd = i.end.split(':')
            self.dayList[index].endHour = {
              value: splitedEnd[0]
            }
            self.dayList[index].endMin = {
              value: splitedEnd[1]
            }
            self.dayList[index].selectedAllDay = false
          }
        }
      })
    }
  },
  methods: {
    onChangeLatLon () {
      const self = this
      if (self.isValidLat && self.isValidLon) {
        self.map.location({ lon: self.location.lon, lat: self.location.lat })
        if (self.isLockLocation) { self.drawMarker() };
      }
    },
    onClickClose () {
      const self = this
      if (self.desktopMode) {
        self.$router.push({
          name: 'Main',
          hash: ''
        })
      } else {
        if (self.isOnMobileApp) {
          self.$router.replace({
            name: 'Place',
            hash: '',
            params: {
              poiId: self.$route.params.poiId
            },
            query: {}
          })
        } else if (window.history.length <= 2) {
          self.$router.replace({
            name: 'Main',
            hash: '',
            params: {},
            query: {}
          })
        } else {
          this.$router.back()
        }
      }
    },
    onWindowResize () {
      const self = this
      if (self.$refs['preview-image-list']) {
        self.previewImageListWidth = self.$refs['preview-image-list'].clientWidth
      }
    },
    loadedLongdoMap (map) {
      const self = this
      self.map = map
      if (self.isLongdoMapV3) {
        self.map.Renderer.on('load', () => {
          try {
            self.map.Ui.Mouse.enable(false)
            self.map.Ui.ContextMenu.visible(false)
            self.map.Ui.ContextMenu.enableAddress(false)
            self.map.Ui.ContextMenu.enableNearPoi(false)
            self.map.Ui.Keyboard.enable(false)
            self.map.Ui.DPad.visible(false)
            self.map.Ui.Geolocation.visible(false)
            self.map.Ui.LayerSelector.visible(false)
            self.map.Ui.Zoombar.visible(false)
            self.map.Ui.Fullscreen.visible(false)
            self.map.Ui.Toolbar.visible(false)
            self.map.Ui.Scale.visible(false)
          } catch (error) {
            console.warn(error)
          }
        })
      } else {
        self.map.zoom(16)
        self.map.Layers.externalOptions({
          googleQuery: 'key=' + process.env.VUE_APP_GOOGLE_MAP_TILE_KEY,
          mapboxKey: process.env.VUE_APP_MAPBOX_KEY
        })
        self.map.Ui.Mouse.enable(false)
        self.map.Ui.ContextMenu.visible(false)
        self.map.Ui.ContextMenu.enableAddress(false)
        self.map.Ui.ContextMenu.enableNearPoi(false)
        self.map.Ui.Keyboard.enable(false)
        self.map.Ui.DPad.visible(false)
        self.map.Ui.Geolocation.visible(false)
        self.map.Ui.LayerSelector.visible(false)
        self.map.Ui.Toolbar.visible(false)
        self.map.Ui.Zoombar.visible(false)
        self.map.Ui.Fullscreen.visible(false)
        self.map.Ui.Scale.visible(false)
      }

      self.map.Event.bind('zoom', () => {
        const currentZoom = map.zoom()
        const zoom = Math.round(currentZoom)
        if (currentZoom !== zoom) {
          self.map.zoom(zoom)
          self.map.resize()
        }
      })
      if (!self.isEditMode && self.initialLocation !== null) {
        self.location.lat = Number(self.initialLocation.lat).toFixed(6)
        self.location.lon = Number(self.initialLocation.lon).toFixed(6)
        if (self.isLongdoMapV3) {
          self.map.Renderer.flyTo({
            center: [Number.parseFloat(self.location.lon), Number.parseFloat(self.location.lat)],
            zoom: 18
          })
        } else {
          self.map.location(self.initialLocation)
        }
      } else if (self.isEditMode) {
        const location = {
          lat: self.detail.location.latitude,
          lon: self.detail.location.longitude
        }
        self.map.location(location)
        self.location.lat = Number(location.lat).toFixed(6)
        self.location.lon = Number(location.lon).toFixed(6)
      } else if (localStorage.lat && localStorage.lon) {
        self.map.location({
          lat: Number(localStorage.lat),
          lon: Number(localStorage.lon)
        }, false)
      }

      // const location = self.map.location()
      // self.location.lat = self.location.lat || Number(location.lat).toFixed(6)
      // self.location.lon = self.location.lon || Number(location.lon).toFixed(6)
      self.onLocationChanged(!self.isEditMode)
      self.drawMarker()

      self.map.Event.bind('drop', () => {
        if (!self.isLockLocation) {
          const location = self.map.location()
          self.location.lat = Number(location.lat).toFixed(6)
          self.location.lon = Number(location.lon).toFixed(6)
          self.onLocationChanged()
        }
      })

      self.map.Event.bind('geolocation', () => {
        if (!self.isLockLocation) {
          const location = self.map.location()
          self.location.lat = Number(location.lat).toFixed(6)
          self.location.lon = Number(location.lon).toFixed(6)
          self.onLocationChanged()
        }
      })

      self.map.Event.bind('contextmenu', (e) => {
        return false
      })

      if (!self.isLongdoMapV3) {
        self.dolLayer = new window.longdo.Layer('', {
          type: window.longdo.LayerType.Custom,
          url: function (projection, tile, zoom, hd) {
            const mode = 'dol_hd'
            return `https://ms.longdo.com/mmmap/img.php?mode=${mode}&proj=${projection.longdoName}&zoom=${zoom}&x=${tile.u}&y=${tile.v}`
          },
          zoomRange: {
            min: 17,
            max: 20
          },
          source: {
            max: 19
          },
          opacity: 0.145
        })
        self.map.Layers.add(self.dolLayer)
      }
      if (self.initialZoom !== null) {
        if (self.initialZoom > 0 && self.initialZoom < 21) {
          self.map.zoom(self.initialZoom)
        }
      }
    },
    onClickNormalLayer () {
      const self = this
      self.isSatellite = false
      if (!self.isLongdoMapV3) {
        self.map.Layers.add(self.dolLayer)
      }
      self.map.Layers.setBase(self.$i18n.locale === 'th' ? window.longdo.Layers.NORMAL : window.longdo.Layers.NORMAL_EN)
    },
    onClickSatelliteLayer () {
      const self = this
      self.isSatellite = true
      if (!self.isLongdoMapV3) {
        self.map.Layers.remove(self.dolLayer)
        // const isLongdoDomain = window.location.origin.indexOf('longdo.com') >= 0 && !self.lji.Util.isAndroidNativeApp() && !self.lji.Util.isIosNativeApp()
        // self.map.Layers.setBase(isLongdoDomain ? window.longdo.Layers.GOOGLE_HYBRID : window.longdo.Layers.THAICHOTE)
        self.map.Layers.setBase(window.longdo.Layers.GOOGLE_HYBRID)
      } else {
        // const isLongdoDomain = window.location.origin.indexOf('longdo.com') >= 0 && !self.lji.Util.isAndroidNativeApp() && !self.lji.Util.isIosNativeApp()
        // self.map.Layers.setBase(isLongdoDomain ? window.longdo.Layers.GOOGLE_HYBRID : window.longdo.Layers.THAICHOTE)
        self.map.Layers.setBase(window.longdo.Layers.GOOGLE_HYBRID)
      }
    },
    async onClickSelectCurrentLocation () {
      const self = this
      if (self.isGeolocationMobileAppDisabled) {
        self.$root.$emit('openMobilePermissionDialog')
        return
      }
      if (self.isLockLocation) {
        self.onClickUnlockMarker()
      }
      if (self.lji.Util.isAndroidNativeApp()) {
        self.map.location({
          lat: Number(window.currentLocation.lat),
          lon: Number(window.currentLocation.lon)
        })
        if (!self.isLockLocation) {
          self.location.lat = Number(window.currentLocation.lat).toFixed(6)
          self.location.lon = Number(window.currentLocation.lon).toFixed(6)
          self.onLocationChanged()
          self.map.Ui.Mouse.enable(true)
        }
      } else if (self.lji.Util.isIosNativeApp()) {
        self.map.location({
          lat: Number(window.currentLocation.lat),
          lon: Number(window.currentLocation.lon)
        })
        if (!self.isLockLocation) {
          self.location.lat = Number(window.currentLocation.lat).toFixed(6)
          self.location.lon = Number(window.currentLocation.lon).toFixed(6)
          self.onLocationChanged()
          self.map.Ui.Mouse.enable(true)
        }
      } else {
        // try {
        //   const result = await self.lji.getGeolocation()
        //   self.map.location({
        //     lat: Number(result.latitude),
        //     lon: Number(result.longitude)
        //   })
        //   if (!self.isLockLocation) {
        //     self.location.lat = Number(result.latitude).toFixed(6)
        //     self.location.lon = Number(result.longitude).toFixed(6)
        //     self.onLocationChanged()
        //   }
        // } catch (e) {
        //   self.map.location(window.longdo.LocationMode.Geolocation)
        // }
        self.map.location(window.longdo.LocationMode.Geolocation)
      }
    },
    onClickLockMarker () {
      const self = this
      self.map.resize()
      self.isLockLocation = true
      self.map.Ui.Mouse.enable(false)
      self.$nextTick(() => {
        const location = self.map.location()
        self.location.lat = Number(location.lat).toFixed(6)
        self.location.lon = Number(location.lon).toFixed(6)
        self.drawMarker()
      })
    },
    onClickUnlockMarker () {
      const self = this
      self.map.resize()
      self.isLockLocation = false
      self.removeMarker()
      self.map.Ui.Mouse.enable(true)
      if (!self.isOnMobileApp) {
        self.$nextTick(() => {
          const location = self.map.location()
          self.location.lat = Number(location.lat).toFixed(6)
          self.location.lon = Number(location.lon).toFixed(6)
          self.onLocationChanged()
        })
      }
    },
    async getTypeList () {
      const self = this
      const params = {
        lang: 'all'
      }
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      if (userSession) {
        if (userSession.longdousertoken !== '' && userSession.longdousertoken !== null && userSession.longdousertoken !== undefined) {
          params.username = userSession.name || userSession.username
          params.longdousertoken = userSession.longdousertoken
        }
      }

      let result = await self.api.getIconList(params)
      result = result.data.result || []
      const tempTypeList = []
      result.forEach(c => {
        const type = {
          value: c.id,
          label: c.description_th,
          labelEn: c.description_en,
          image: c.imagefileurl
        }
        if (c.id === '64' || c.id === '65') {
          tempTypeList.unshift(type)
        } else {
          tempTypeList.push(type)
        }
      })
      self.typeList = tempTypeList
    },
    async getTagSuggestList () {
      const self = this
      const result = await self.api.getAutoCompleteTag(self.tags)
      if ((result || {}).data) {
        self.tagSuggestList = Object.keys(result.data)
      } else {
        self.tagSuggestList = self.tags !== '' ? [self.tags] : []
      }
    },
    onSelectTagSuggest (value) {
      const self = this
      self.tags = value
    },
    async getParentList () {
      const self = this

      if (!self.isValidLat || !self.isValidLon) {
        self.parentList = []
        return false
      }

      const data = self.location
      if (self.isEditMode) {
        data.id = self.detail.id
      }

      const result = await self.api.getParentPlace(data)
      self.parentList = result.data.data.map(i => {
        return {
          value: i.id,
          label: i.name_th,
          labelEn: i.name_en
        }
      })
    },
    async getCountryList () {
      const self = this
      const result = await self.api.getCountryList()
      self.countryList = result.data
    },
    onLocationChanged (isGetAddress = true) {
      const self = this
      isGetAddress = (isGetAddress === undefined || isGetAddress === null) ? true : isGetAddress
      if (self.updateAddessTask) {
        clearTimeout(self.updateAddessTask)
      }
      self.updateAddessTask = setTimeout(() => {
        self.$nextTick(() => {
          self.getParentList()
          if (isGetAddress) {
            self.getAddress()
          }
        })
      }, 300)
    },
    drawMarker () {
      const self = this
      self.removeMarker()
      if (self.isLongdoMapV3) {
        if (!self.map3Ready) {
          self.map.Event.bind('ready', () => {
            self.marker = new window.longdo.Marker({
              lon: Number.parseFloat(self.location.lon),
              lat: Number.parseFloat(self.location.lat)
            })
            self.map.Overlays.add(self.marker)
            self.map3Ready = true
          })
        } else { // Map ready
          self.marker = new window.longdo.Marker({
            lon: Number.parseFloat(self.location.lon),
            lat: Number.parseFloat(self.location.lat)
          })
          self.map.Overlays.add(self.marker)
        }
        localStorage.setItem('lon', self.location.lon)
        localStorage.setItem('lat', self.location.lat)
      } else {
        self.marker = new window.longdo.Marker(self.location, {
          icon: {
            html: '<img style="width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px;" src="' + process.env.VUE_APP_LONGDO_MAP_PIN + '">',
            offset: {
              x: 15,
              y: 42
            }
          }
        })
        self.map.Overlays.add(self.marker)
      }
    },
    removeMarker () {
      const self = this
      if (self.marker) {
        self.map.Overlays.remove(self.marker)
      }
    },
    getAddress () {
      const self = this

      self.moo = ''
      self.street.th = ''
      self.street.en = ''
      self.road.th = ''
      self.road.en = ''
      self.subdistrict.th = ''
      self.subdistrict.en = ''
      self.district.th = ''
      self.district.en = ''
      self.province.th = ''
      self.province.en = ''
      self.postcode = ''

      if (!self.isValidLat || !self.isValidLon) {
        return false
      }

      self.api.getAddress({
        lat: self.location.lat,
        lon: self.location.lon,
        locale: 'th'
      }).then(result => {
        if (!(result.data || false)) {
          return false
        }

        if (result.data.road) {
          const indexOfSoi = result.data.road.indexOf('ซอย')
          const indexOfSoiWithParentheses = result.data.road.indexOf('(ซอย')
          const foundRoadAndSoi = indexOfSoi > 0 && indexOfSoiWithParentheses >= 0
          if (foundRoadAndSoi) {
            self.street.th = result.data.road.substring(indexOfSoiWithParentheses + 1).replaceAll(/\)/g, '')
            self.road.th = result.data.road.substring(0, indexOfSoiWithParentheses)
          }
          const foundSoi = indexOfSoi === 0
          if (foundSoi) {
            self.street.th = result.data.road
          }
          const foundRoad = indexOfSoi < 0
          if (foundRoad) {
            self.road.th = result.data.road
          }
        }
        if (result.data.geocode) {
          self.subdistrict.code = result.data.geocode
          self.district.code = result.data.geocode.substring(0, 4)
          self.province.code = result.data.geocode.substring(0, 2)
        }
        if (result.data.village_no) {
          self.moo = result.data.village_no
        }
        if (result.data.subdistrict) {
          self.subdistrict.th = result.data.subdistrict
        }
        if (result.data.district) {
          self.district.th = result.data.district
        }
        if (result.data.province) {
          self.province.th = result.data.province
        }
        if (result.data.postcode) {
          self.postcode = result.data.postcode
        }
        if (result.data.country) {
          self.country.th = result.data.country === 'ประเทศไทย' ? 'ไทย' : result.data.country
        }
      })

      self.api.getAddress({
        lat: self.location.lat,
        lon: self.location.lon,
        locale: 'en'
      }).then(result => {
        if (!(result.data || false)) {
          return false
        }

        if (result.data.road) {
          const indexOfSoi = result.data.road.indexOf('Soi')
          const indexOfSoiWithParentheses = result.data.road.indexOf('(Soi')
          const foundRoadAndSoi = indexOfSoi > 0 && indexOfSoiWithParentheses >= 0
          if (foundRoadAndSoi) {
            self.street.en = result.data.road.substring(indexOfSoiWithParentheses + 1).replaceAll(/\)/g, '')
            self.road.en = result.data.road.substring(0, indexOfSoiWithParentheses)
          }
          const foundSoi = indexOfSoi === 0
          if (foundSoi) {
            self.street.en = result.data.road
          }
          const foundRoad = indexOfSoi < 0
          if (foundRoad) {
            self.road.en = result.data.road
          }
        }
        if (result.data.geocode) {
          self.subdistrict.code = result.data.geocode
          self.district.code = result.data.geocode.substring(0, 4)
          self.province.code = result.data.geocode.substring(0, 2)
        }
        if (result.data.village_no) {
          self.moo = result.data.village_no
        }
        if (result.data.subdistrict) {
          self.subdistrict.en = result.data.subdistrict
        }
        if (result.data.district) {
          self.district.en = result.data.district
        }
        if (result.data.province) {
          self.province.en = result.data.province
        }
        if (result.data.postcode) {
          self.postcode = result.data.postcode
        }
        if (result.data.country) {
          self.country.en = result.data.country
        }
      })
    },
    addTel () {
      const self = this
      self.telList.push({
        id: `_${new Date().getTime()}`,
        type: 'T',
        countryCode: '66',
        areaCode: '',
        telNo: '',
        telNoTo: '',
        ext: '',
        remark: '',
        lineId: '',
        lineLink: '',
        isValid: false
      })
    },
    deleteContact (index) {
      const self = this
      self.telList.splice(index, 1)
    },
    async beforeSave () {
      const self = this
      if (self.isAdmin) {
        await self.savePlace()
        return
      }
      self.isShowAlertBeforeSave = true
    },
    async savePlace () {
      const self = this
      self.isShowAlertBeforeSave = false

      if (self.isSavingPlace) {
        return false
      }
      self.isSavingPlace = true
      self.createErrorMessage = ''

      self.telList = self.telList.filter(t => {
        return (t.type === 'L' && (t.lineId || t.lineLink)) || (t.type !== 'L' && t.telNo)
      })

      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      if (!(userSession || false)) {
        self.isSavingPlace = false
        return false
      }
      if (!(userSession.longdousertoken || false)) {
        self.isSavingPlace = false
        return false
      }

      if (
        self.street.th !== '' &&
        self.street.th.indexOf('ซ.') !== 0 &&
        self.street.th.indexOf('ซอย') !== 0
      ) {
        self.street.th = `ซอย ${self.street.th}`
      }
      if (self.street.en !== '' && self.street.en.indexOf('Soi') !== 0) {
        self.street.en = `Soi ${self.street.en}`
      }
      if (
        self.road.th !== '' &&
        self.road.th.indexOf('ถ.') !== 0 &&
        self.road.th.indexOf('ถนน') !== 0
      ) {
        self.road.th = `ถนน${self.road.th}`
      }
      if (
        self.road.en !== '' &&
        self.road.en.indexOf('Road') !== self.road.en.length - 4 &&
        self.road.en.indexOf('Rd.') !== self.road.en.length - 3
      ) {
        self.road.en = `${self.road.en} Rd.`
      }

      const data = {
        username: userSession.name || userSession.username,
        ldtoken: userSession.longdousertoken,
        latitude: self.location.lat,
        longitude: self.location.lon,
        title: (self.placeName.th || '').trim(),
        title_en: (self.placeName.en || self.placeName.th || '').trim(),
        iconfile: self.type.value,
        tag: self.tags || '',
        house_no: (self.isThailand ? (self.houseNo || '') : '').trim(),
        moo: (self.isThailand ? (self.moo || '') : '').trim(),
        pid: self.isThailand ? (self.parent.value || '') : '',
        floor: (self.floor || '').trim(),
        room: (self.roomNo || '').trim(),
        village_th: (self.village.th || '').trim(),
        village_en: (self.village.en || '').trim(),
        condo_th: (self.condo.th || '').trim(),
        condo_en: (self.condo.en || '').trim(),
        building_th: (self.building.th || '').trim(),
        building_en: (self.building.en || '').trim(),
        alley_th: (self.isThailand ? (self.street.th || '') : '').trim(),
        alley_en: (self.isThailand ? (self.street.en || '') : '').trim(),
        road_th: (self.road.th || '').trim(),
        road_en: (self.road.en || '').trim(),
        subdistrict_th: (self.subdistrict.th || '').trim(),
        subdistrict_en: (self.subdistrict.en || '').trim(),
        district_th: (self.district.th || '').trim(),
        district_en: (self.district.en || '').trim(),
        province_th: (self.province.th || '').trim(),
        province_en: (self.province.en || '').trim(),
        postcode: (self.postcode || '').trim(),
        country_th: (self.country.th || '').trim(),
        country_en: (self.country.en || '').trim(),
        address_detail_th: (self.description.th || '').trim(),
        address_detail_en: (self.description.en || '').trim(),
        telephone: JSON.stringify(self.telList.map(t => {
          return {
            type: t.type,
            country_code: ((t.type !== 'S' && t.type !== 'L') ? t.countryCode : '').trim(),
            area_code: '', // (t.type !== 'S' && t.type !== 'L') ? t.areaCode : '',
            tel_no: (((t.type !== 'S' && t.type !== 'L') ? t.areaCode : '') + (t.type !== 'L' ? t.telNo : '')).trim(),
            tel_no_to: ((t.type !== 'M' && t.type !== 'L') ? t.telNoTo : '').trim(),
            ext: ((t.type !== 'M' && t.type !== 'L') ? t.ext : '').trim(),
            remark: (t.remark || '').trim(),
            line_id: (t.type === 'L' ? t.lineId : '').trim(),
            line_link: (t.type === 'L' ? t.lineLink : '').trim()
          }
        })),
        url: (self.websiteUrl || '').trim(),
        image: self.imageList.filter(i => (i.file !== null)).map(i => i.original),
        imagecomment: self.imageList.filter(i => (i.file !== null)).map(i => (i.comment || '').trim()),
        full_description_th: (self.fullDescription.th || '').trim(),
        full_description_en: (self.fullDescription.en || '').trim(),
        working_hours: JSON.stringify({
          remark: (self.workingHoursRemark || '').trim(),
          hours: self.dayList.filter(d => {
            return d.selected
          }).map(d => {
            return {
              day: d.name,
              start: d.selectedAllDay ? '00:00' : `${d.startHour.value}:${d.startMin.value}`,
              end: d.selectedAllDay ? '24:00' : `${d.endHour.value}:${d.endMin.value}`
            }
          })
        })
      }

      if (self.isEditMode) {
        data.ooiid = self.detail.id

        if (self.isAdmin) {
          if (self.isPrivate.value) {
            data.private = self.isPrivate.value
          }
          if (self.status.value) {
            data.status = self.status.value
          }
          if (self.locked.value) {
            data.locked = self.locked.value
          }
        }
      }

      const result = await self.api.savePlace(data)
      if (result.data && result.status === 200) {
        const resultMessage = ((result.data || {}).result || {}).rs_msg || ''
        if (resultMessage === 'Success') {
          var dataForSuccessEvent = {
            poiId: self.isEditMode ? self.detail.id : result.data.result.ooiid,
            lat: data.latitude,
            lon: data.longitude,
            nameTh: data.title,
            nameEn: data.title_en,
            typeIconUrl: self.type.image,
            addressTh: '',
            addressEn: ''
          }
          const placeDetailResult = await self.api.getPlaceDetail({
            id: self.isEditMode ? self.detail.id : result.data.result.ooiid,
            username: data.username,
            ldtoken: data.ldtoken
          })
          if (placeDetailResult.status) {
            dataForSuccessEvent.addressTh = placeDetailResult.data.data.address_th
            dataForSuccessEvent.addressEn = placeDetailResult.data.data.address_en
          }
          self.createSuccessMessage = self.$t('createPlace.defaultCreateSuccessMessage')
          setTimeout(() => {
            self.isSavingPlace = false
            self.createSuccessMessage = ''
            self.$emit('success', dataForSuccessEvent)
          }, 2 * 1000)
        } else {
          self.createErrorMessage = resultMessage
          setTimeout(() => {
            self.isSavingPlace = false
            self.createErrorMessage = ''
          }, 2 * 1000)
        }
      } else {
        self.createErrorMessage = self.$t('createPlace.defaultCreateErrorMessage')
        setTimeout(() => {
          self.isSavingPlace = false
          self.createErrorMessage = ''
        }, 2 * 1000)
      }
    },
    onClickAddImage () {
      const self = this
      self.$refs['add-image-input'].click()
    },
    manageImageList () {
      const self = this
      const imageFileList = self.$refs['add-image-input'].files

      const loadImage = (index) => {
        return (e) => {
          self.utility.resizeImage(e.target.result, 2048, 2048, self.imageList[index].orientation, (originalUrl) => {
            self.imageList[index].original = originalUrl
          })
          self.utility.resizeImage(e.target.result, self.previewImageMinWidth * 2, self.previewImageMinWidth * 2, self.imageList[index].orientation, (previewUrl) => {
            self.imageList[index].preview = previewUrl
          })
        }
      }

      const orientationCallback = function (index) {
        return (orientation) => {
          self.imageList[index].orientation = orientation
          const reader = new FileReader()
          reader.onload = loadImage(index)
          reader.readAsDataURL(self.imageList[index].file)
        }
      }

      for (let i = 0; i < imageFileList.length; i++) {
        if (imageFileList[i]) {
          self.imageList.push({
            file: imageFileList[i],
            original: '',
            preview: '',
            comment: '',
            orientation: -1
          })
          orientationCallback(self.imageList.length - 1)(self.imageList[self.imageList.length - 1].orientation)
        }
      }

      self.$refs['add-image-input'].value = ''
    },
    onDeleteImage (index) {
      const self = this
      self.imageList.splice(index, 1)
    },
    getSubdistrictSuggestList (callback) {
      const self = this
      const keyword = self.langMode === 'th' ? self.subdistrict.th : self.subdistrict.en
      if (self.suggestSubdistrictTask) {
        clearTimeout(self.suggestSubdistrictTask)
      }
      self.suggestSubdistrictTask = setTimeout(async () => {
        const primaryResult = await self.api.searchPlaceRestApi({
          locale: self.langMode,
          lat: self.location.lat,
          lon: self.location.lon,
          keyword: keyword.length < 2 ? '' : keyword,
          tag: 'subdistrict',
          dataset: 'data2a',
          area: self.district.code || self.province.code || ''
        })
        if (self.langMode === 'th') {
          self.subdistrictThSuggestList = primaryResult.data.data
        } else {
          self.subdistrictEnSuggestList = primaryResult.data.data
        }

        const secondaryResult = await self.api.searchPlaceRestApi({
          locale: self.langMode === 'th' ? 'en' : 'th',
          lat: self.location.lat,
          lon: self.location.lon,
          keyword: keyword.length < 2 ? '' : keyword,
          tag: 'subdistrict',
          dataset: 'data2a',
          area: self.district.code || self.province.code || ''
        })
        if (self.langMode === 'th') {
          self.subdistrictEnSuggestList = secondaryResult.data.data
        } else {
          self.subdistrictThSuggestList = secondaryResult.data.data
        }

        if (callback) {
          callback()
        }
      }, 300)
    },
    onSelectSubdistrictThSuggest (value) {
      const self = this
      const spliter = ' '
      const splitedValue = value.split(spliter)
      if (splitedValue.length > 0) {
        const index = self.subdistrictThSuggestList.map(p => p.name).indexOf(value)
        if (index >= 0) {
          self.subdistrict.code = self.subdistrictThSuggestList[index].address.replace(/รหัส: /g, '').replace(/Code: /g, '')
          self.subdistrictThSuggestList[index].tag.forEach(t => {
            if (t.indexOf('__POST') >= 0) {
              self.postcode = t.replace('__POST', '')
            }
          })
        }
        self.subdistrict.th = splitedValue[0]
      }
      if (splitedValue.length > 1) {
        self.district.th = splitedValue[1]
      }
      if (splitedValue.length > 2) {
        self.province.th = splitedValue[2]
      }
    },
    onSelectSubdistrictEnSuggest (value) {
      const self = this
      const spliter = ', '
      const splitedValue = value.split(spliter)
      if (splitedValue.length > 0) {
        const index = self.subdistrictEnSuggestList.map(p => p.name).indexOf(value)
        if (index >= 0) {
          self.subdistrict.code = self.subdistrictEnSuggestList[index].address.replace(/รหัส: /g, '').replace(/Code: /g, '')
          self.subdistrictEnSuggestList[index].tag.forEach(t => {
            if (t.indexOf('__POST') >= 0) {
              self.postcode = t.replace('__POST', '')
            }
          })
        }
        self.subdistrict.en = splitedValue[0]
      }
      if (splitedValue.length > 1) {
        self.district.en = splitedValue[1]
      }
      if (splitedValue.length > 2) {
        self.province.en = splitedValue[2]
      }
    },
    getDistrictSuggestList (callback) {
      const self = this
      const keyword = self.langMode === 'th' ? self.district.th : self.district.en
      if (self.suggestDistrictTask) {
        clearTimeout(self.suggestDistrictTask)
      }
      self.suggestDistrictTask = setTimeout(async () => {
        const primaryResult = await self.api.searchPlaceRestApi({
          locale: self.langMode,
          lat: self.location.lat,
          lon: self.location.lon,
          keyword: keyword.length < 2 ? '' : keyword,
          tag: 'district',
          dataset: 'data2a',
          area: self.province.code || ''
        })
        if (self.langMode === 'th') {
          self.districtThSuggestList = primaryResult.data.data
        } else {
          self.districtEnSuggestList = primaryResult.data.data
        }

        const secondaryResult = await self.api.searchPlaceRestApi({
          locale: self.langMode === 'th' ? 'en' : 'th',
          lat: self.location.lat,
          lon: self.location.lon,
          keyword: keyword.length < 2 ? '' : keyword,
          tag: 'district',
          dataset: 'data2a',
          area: self.province.code || ''
        })
        if (self.langMode === 'th') {
          self.districtEnSuggestList = secondaryResult.data.data
        } else {
          self.districtThSuggestList = secondaryResult.data.data
        }

        if (callback) {
          callback()
        }
      }, 300)
    },
    onSelectDistrictThSuggest (value) {
      const self = this
      const spliter = ' '
      const splitedValue = value.split(spliter)
      if (splitedValue.length > 0) {
        const index = self.districtThSuggestList.map(p => p.name).indexOf(value)
        if (index >= 0) {
          self.district.code = self.districtThSuggestList[index].address.replace(/รหัส: /g, '').replace(/Code: /g, '')
        }
        self.district.th = splitedValue[0]

        self.postcode = ''
        self.subdistrict.th = ''
        self.subdistrict.en = ''
        self.subdistrict.code = ''

        self.$nextTick(() => {
          self.getSubdistrictSuggestList()
        })
      }
      if (splitedValue.length > 1) {
        self.province.th = splitedValue[1]
      }
    },
    onSelectDistrictEnSuggest (value) {
      const self = this
      const spliter = ', '
      const splitedValue = value.split(spliter)
      if (splitedValue.length > 0) {
        const index = self.districtEnSuggestList.map(p => p.name).indexOf(value)
        if (index >= 0) {
          self.district.code = self.districtEnSuggestList[index].address.replace(/รหัส: /g, '').replace(/Code: /g, '')
        }
        self.district.en = splitedValue[0]

        self.postcode = ''
        self.subdistrict.th = ''
        self.subdistrict.en = ''
        self.subdistrict.code = ''

        self.$nextTick(() => {
          self.getSubdistrictSuggestList()
        })
      }
      if (splitedValue.length > 1) {
        self.province.en = splitedValue[1]
      }
    },
    getProvinceSuggestList (callback) {
      const self = this
      const keyword = self.langMode === 'th' ? self.province.th : self.province.en
      if (self.suggestProvinceTask) {
        clearTimeout(self.suggestProvinceTask)
      }
      self.suggestProvinceTask = setTimeout(async () => {
        const primaryResult = await self.api.searchPlaceRestApi({
          locale: self.langMode,
          lat: self.location.lat,
          lon: self.location.lon,
          keyword: keyword.length < 2 ? '' : keyword,
          tag: 'province',
          dataset: 'data2a',
          limit: 100
        })
        if (self.langMode === 'th') {
          self.provinceThSuggestList = primaryResult.data.data
        } else {
          self.provinceEnSuggestList = primaryResult.data.data
        }

        const secondaryResult = await self.api.searchPlaceRestApi({
          locale: self.langMode === 'th' ? 'en' : 'th',
          lat: self.location.lat,
          lon: self.location.lon,
          keyword: keyword.length < 2 ? '' : keyword,
          tag: 'province',
          dataset: 'data2a',
          limit: 100
        })
        if (self.langMode === 'th') {
          self.provinceEnSuggestList = secondaryResult.data.data
        } else {
          self.provinceThSuggestList = secondaryResult.data.data
        }

        if (callback) {
          callback()
        }
      }, 300)
    },
    onSelectProvinceThSuggest (value) {
      const self = this
      var spliter = ' '
      var splitedValue = value.split(spliter)
      if (splitedValue.length > 0) {
        const index = self.provinceThSuggestList.map(p => p.name).indexOf(value)
        if (index >= 0) {
          self.province.code = self.provinceThSuggestList[index].address.replace(/รหัส: /g, '').replace(/Code: /g, '')
        }
        self.province.th = splitedValue[0]

        self.postcode = ''
        self.subdistrict.th = ''
        self.district.th = ''
        self.subdistrict.en = ''
        self.district.en = ''
        self.subdistrict.code = ''
        self.district.code = ''

        self.$nextTick(() => {
          self.getDistrictSuggestList()
          self.getSubdistrictSuggestList()
        })
      }
    },
    onSelectProvinceEnSuggest (value) {
      const self = this
      var spliter = ', '
      var splitedValue = value.split(spliter)
      if (splitedValue.length > 0) {
        const index = self.provinceEnSuggestList.map(p => p.name).indexOf(value)
        if (index >= 0) {
          self.province.code = self.provinceEnSuggestList[index].address.replace(/รหัส: /g, '').replace(/Code: /g, '')
        }
        self.province.en = splitedValue[0]

        self.postcode = ''
        self.subdistrict.th = ''
        self.district.th = ''
        self.subdistrict.en = ''
        self.district.en = ''
        self.subdistrict.code = ''
        self.district.code = ''

        self.$nextTick(() => {
          self.getDistrictSuggestList()
        })
      }
    },
    onSelectCountryThSuggest (value) {
      const self = this
      self.country.th = value
      const index = self.countryList.map(c => c.name).indexOf(value)
      if (index >= 0) {
        self.country.en = self.countryList[index].nameEn
      }
    },
    onSelectCountryEnSuggest (value) {
      const self = this
      self.country.en = value
      const index = self.countryList.map(c => c.nameEn).indexOf(value)
      if (index >= 0) {
        self.country.th = self.countryList[index].name
      }
    },
    clickChangeLangMode () {
      const self = this
      self.langMode = self.langMode === 'th' ? 'en' : 'th'
    },
    onChangeDayCheckbox (index) {
      const self = this
      if (!self.dayList[index].selected) {
        self.dayList[index].startHour = {
          value: '09'
        }
        self.dayList[index].startMin = {
          value: '00'
        }
        self.dayList[index].endHour = {
          value: '18'
        }
        self.dayList[index].endMin = {
          value: '00'
        }
        self.dayList[index].selectedAllDay = false
      } else {
        self.dayList[index].startHour = {
          value: ''
        }
        self.dayList[index].startMin = {
          value: ''
        }
        self.dayList[index].endHour = {
          value: ''
        }
        self.dayList[index].endMin = {
          value: ''
        }
        self.dayList[index].selectedAllDay = false
      }
    },
    onChangeAllDayCheckbox (index) {
      const self = this
      if (!self.dayList[index].selectedAllDay) {
        self.dayList[index].startHour = {
          value: ''
        }
        self.dayList[index].startMin = {
          value: ''
        }
        self.dayList[index].endHour = {
          value: ''
        }
        self.dayList[index].endMin = {
          value: ''
        }
      } else {
        self.dayList[index].startHour = {
          value: '09'
        }
        self.dayList[index].startMin = {
          value: '00'
        }
        self.dayList[index].endHour = {
          value: '18'
        }
        self.dayList[index].endMin = {
          value: '00'
        }
      }
    }
  },
  watch: {
    tags (newTags) {
      const self = this
      self.$nextTick(() => {
        if (self.getTagSuggestListTask) {
          clearTimeout(self.getTagSuggestListTask)
        }
        self.getTagSuggestListTask = setTimeout(() => {
          self.getTagSuggestList()
        }, 300)
      })
    },
    'location.lat' (newLat) {
      const self = this
      let isValid = true
      isValid = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(newLat)
      if (!(newLat || false)) {
        isValid = false
      } else {
        if (Number(newLat) > 85 || Number(newLat) < -85) {
          isValid = false
        }
      }
      self.isValidLat = isValid
    },
    'location.lon' (newLon) {
      const self = this
      let isValid = true
      isValid = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(newLon)
      if (!(newLon || false)) {
        isValid = false
      } else {
        if (Number(newLon) >= 180 || Number(newLon) <= -180) {
          isValid = false
        }
      }
      self.isValidLon = isValid
    },
    type (newType, oldType) {
      const self = this
      if (oldType.value === '64') {
        self.placeName.th = ''
        self.placeName.en = ''
      }
      if (newType.value === '64') {
        self.placeName.th = 'บ้าน / ที่อยู่อาศัย'
        self.placeName.en = 'House / Residence'
        if (self.houseNo) {
          self.placeName.th = self.houseNo
          self.placeName.en = self.houseNo
        }
      }
    },
    'placeName.th' (newPlaceNameTh) {
      const self = this
      let isValid = true
      newPlaceNameTh = (newPlaceNameTh || '').trim()
      if (!newPlaceNameTh) {
        isValid = false
      }
      self.isValidPlaceName.th = isValid
      if (!(/[\u0E00-\u0E7F]/.test(newPlaceNameTh)) && self.placeName.en === '') {
        self.isValidPlaceName.en = true
      }
      if ((/[\u0E00-\u0E7F]/.test(newPlaceNameTh)) && self.placeName.en === '') {
        self.isValidPlaceName.en = false
      }
    },
    'placeName.en' (newPlaceNameEn) {
      const self = this
      let isValid = true
      newPlaceNameEn = (newPlaceNameEn || '').trim()
      if (!newPlaceNameEn) {
        isValid = false
      }
      if (/[\u0E00-\u0E7F]/.test(newPlaceNameEn)) {
        isValid = false
      }
      if (!(/[\u0E00-\u0E7F]/.test(self.placeName.th)) && newPlaceNameEn === '') {
        isValid = true
      }
      self.isValidPlaceName.en = isValid
    },
    'houseNo' (houseNo) {
      const self = this
      let isValid = true
      houseNo = (houseNo || '').trim()
      isValid = (/^[0-9/,-]+$/.test(self.houseNo) || houseNo === '')
      self.isValidHouseNo = isValid
      if (self.type.value === '64') {
        if (isValid && houseNo) {
          self.placeName.th = houseNo
          self.placeName.en = houseNo
        }
      }
    },
    'moo' (newMoo) {
      const self = this
      let isValid = true
      newMoo = (newMoo || '').trim()
      isValid = (/^\d+$/.test(self.moo) || newMoo === '')
      self.isValidMoo = isValid
    },
    'condo.th' (newCondo) {
      const self = this
      if (newCondo === '') {
        self.isValidCondoName.th = true
      }
      newCondo = (newCondo).trim()
      if ((/[\u0E00-\u0E7F]/.test(newCondo)) && self.condo.th !== '') {
        self.isValidCondoName.th = true
      }
      if (!(/[\u0E00-\u0E7F]/.test(newCondo)) && self.condo.th !== '') {
        self.isValidCondoName.th = false
      }
    },
    'condo.en' (newCondo) {
      const self = this
      if (newCondo === '') {
        self.isValidCondoName.en = true
      }
      newCondo = (newCondo).trim()
      self.isValidCondoName.en = true
      if ((/[\u0E00-\u0E7F]/.test(newCondo)) && self.condo.en !== '') {
        self.isValidCondoName.en = false
      }
    },
    'building.th' (newBuilding) {
      const self = this
      if (newBuilding === '') {
        self.isValidBuildingName.th = true
      }
      // Check is building no only ?
      if ((/^\d+$/).test(newBuilding)) {
        return true
      }
      newBuilding = (newBuilding).trim()
      if ((/[\u0E00-\u0E7F]/.test(newBuilding)) && self.building.th !== '') {
        self.isValidBuildingName.th = true
      }
      if (!(/[\u0E00-\u0E7F]/.test(newBuilding)) && self.building.th !== '') {
        self.isValidBuildingName.th = false
      }
    },
    'building.en' (newBuilding) {
      const self = this
      newBuilding = (newBuilding).trim()
      if (newBuilding === '') {
        self.isValidBuildingName.en = true
      }
      self.isValidBuildingName.en = true
      if ((/[\u0E00-\u0E7F]/.test(newBuilding)) && self.building.en !== '') {
        self.isValidBuildingName.en = false
      }
    },
    'village.th' (newVillage) {
      const self = this
      if (newVillage === '') {
        self.isValidVillageName.th = true
      }
      newVillage = (newVillage).trim()
      if ((/[\u0E00-\u0E7F]/.test(newVillage)) && self.village.th !== '') {
        self.isValidVillageName.th = true
      }
      if (!(/[\u0E00-\u0E7F]/.test(newVillage)) && self.village.th !== '') {
        self.isValidVillageName.th = false
      }
    },
    'village.en' (newVillage) {
      const self = this
      if (newVillage === '') {
        self.isValidVillageName.en = true
      }
      newVillage = (newVillage).trim()
      self.isValidVillageName.en = true
      if ((/[\u0E00-\u0E7F]/.test(newVillage)) && self.village.en !== '') {
        self.isValidVillageName.en = false
      }
    },
    'subdistrict.th' (newSubdistrictTh) {
      const self = this

      let isValid = true
      newSubdistrictTh = (newSubdistrictTh || '').trim()
      if (self.isThailand && !(newSubdistrictTh || false)) {
        isValid = false
      }
      self.isValidSubdistrict.th = isValid

      self.$nextTick(() => {
        if (self.subdistrict.th === '') {
          self.subdistrict.th = ''
          self.subdistrict.en = ''
          self.subdistrict.code = ''
          self.postcode = ''
        }

        if (self.subdistrictThSuggestList.map(s => s.name).indexOf(self.subdistrict.th) <= 0) {
          self.getSubdistrictSuggestList(() => {
            if (self.langMode === 'th') {
              const address = [self.subdistrict.th, self.district.th, self.province.th].join(' ')
              const index = self.subdistrictThSuggestList.map(s => s.name).indexOf(address)
              if (index >= 0) {
                var spliter = ', '
                var splitedValue = self.subdistrictEnSuggestList[index].name.split(spliter)
                if (splitedValue.length > 0) {
                  self.subdistrict.en = splitedValue[0]
                }
                if (splitedValue.length > 1) {
                  self.district.en = splitedValue[1]
                }
                if (splitedValue.length > 2) {
                  self.province.en = splitedValue[2]
                }
              } else {
                self.subdistrict.code = ''
              }
            }
          })
        }
      })
    },
    'subdistrict.en' (newSubdistrictEn) {
      const self = this

      let isValid = true
      newSubdistrictEn = (newSubdistrictEn || '').trim()
      if (self.isThailand && !(newSubdistrictEn || false)) {
        isValid = false
      }
      if (/[\u0E00-\u0E7F]/.test(newSubdistrictEn)) {
        isValid = false
      }
      self.isValidSubdistrict.en = isValid

      self.$nextTick(() => {
        if (self.subdistrict.en === '') {
          self.subdistrict.th = ''
          self.subdistrict.en = ''
          self.subdistrict.code = ''
          self.postcode = ''
        }

        if (self.subdistrictEnSuggestList.map(s => s.name).indexOf(self.subdistrict.en) <= 0) {
          self.getSubdistrictSuggestList(() => {
            if (self.langMode === 'en') {
              const address = [self.subdistrict.en, self.district.en, self.province.en].join(', ')
              const index = self.subdistrictEnSuggestList.map(s => s.name).indexOf(address)
              if (index >= 0) {
                var spliter = ' '
                var splitedValue = self.subdistrictThSuggestList[index].name.split(spliter)
                if (splitedValue.length > 0) {
                  self.subdistrict.th = splitedValue[0]
                }
                if (splitedValue.length > 1) {
                  self.district.th = splitedValue[1]
                }
                if (splitedValue.length > 2) {
                  self.province.th = splitedValue[2]
                }
              } else {
                self.subdistrict.code = ''
              }
            }
          })
        }
      })
    },
    'district.th' (newDistrictTh) {
      const self = this

      let isValid = true
      newDistrictTh = (newDistrictTh || '').trim()
      if (self.isThailand && !(newDistrictTh || false)) {
        isValid = false
      }
      self.isValidDistrict.th = isValid

      self.$nextTick(() => {
        if (self.district.th === '') {
          self.district.th = ''
          self.district.en = ''
          self.district.code = ''
          self.subdistrict.th = ''
          self.subdistrict.en = ''
          self.subdistrict.code = ''
          self.postcode = ''
        }

        if (self.districtThSuggestList.map(d => d.name).indexOf(self.district.th) <= 0) {
          self.getDistrictSuggestList(() => {
            if (self.langMode === 'th') {
              const address = [self.district.th, self.province.th].join(' ')
              const index = self.districtThSuggestList.map(d => d.name).indexOf(address)
              if (index >= 0) {
                var spliter = ', '
                var splitedValue = self.districtEnSuggestList[index].name.split(spliter)
                if (splitedValue.length > 0) {
                  self.district.en = splitedValue[0]
                }
                if (splitedValue.length > 1) {
                  self.province.en = splitedValue[1]
                }
              } else {
                self.district.code = ''
              }
            }
          })
        }
      })
    },
    'district.en' (newDistrictEn) {
      const self = this

      let isValid = true
      newDistrictEn = (newDistrictEn || '').trim()
      if (self.isThailand && !(newDistrictEn || false)) {
        isValid = false
      }
      if (/[\u0E00-\u0E7F]/.test(newDistrictEn)) {
        isValid = false
      }
      self.isValidDistrict.en = isValid

      self.$nextTick(() => {
        if (self.district.en === '') {
          self.district.th = ''
          self.district.en = ''
          self.district.code = ''
          self.subdistrict.th = ''
          self.subdistrict.en = ''
          self.subdistrict.code = ''
          self.postcode = ''
        }

        if (self.districtEnSuggestList.map(d => d.name).indexOf(self.district.en) <= 0) {
          self.getDistrictSuggestList(() => {
            if (self.langMode === 'en') {
              const address = [self.district.en, self.province.en].join(', ')
              const index = self.districtEnSuggestList.map(d => d.name).indexOf(address)
              if (index >= 0) {
                var spliter = ' '
                var splitedValue = self.districtThSuggestList[index].name.split(spliter)
                if (splitedValue.length > 0) {
                  self.district.th = splitedValue[0]
                }
                if (splitedValue.length > 1) {
                  self.province.th = splitedValue[1]
                }
              } else {
                self.district.code = ''
              }
            }
          })
        }
      })
    },
    'province.th' (newProvinceTh) {
      var self = this

      let isValid = true
      newProvinceTh = (newProvinceTh || '').trim()
      if (self.isThailand && !(newProvinceTh || false)) {
        isValid = false
      }
      self.isValidProvince.th = isValid

      self.$nextTick(() => {
        if (self.province.th === '') {
          self.province.th = ''
          self.province.en = ''
          self.province.code = ''
          self.district.th = ''
          self.district.en = ''
          self.district.code = ''
          self.subdistrict.th = ''
          self.subdistrict.en = ''
          self.subdistrict.code = ''
          self.postcode = ''
        }
        self.getProvinceSuggestList(() => {
          if (self.langMode === 'th') {
            const index = self.provinceThSuggestList.map(p => p.name).indexOf(self.province.th)
            if (index >= 0) {
              self.province.en = self.provinceEnSuggestList[index].name
            } else {
              self.province.code = ''
            }
          }
        })
      })
    },
    'province.en' (newProvinceEn) {
      var self = this

      let isValid = true
      newProvinceEn = (newProvinceEn || '').trim()
      if (self.isThailand && !(newProvinceEn || false)) {
        isValid = false
      }
      if (/[\u0E00-\u0E7F]/.test(newProvinceEn)) {
        isValid = false
      }
      self.isValidProvince.en = isValid

      self.$nextTick(() => {
        if (self.province.en === '') {
          self.province.th = ''
          self.province.en = ''
          self.province.code = ''
          self.district.th = ''
          self.district.en = ''
          self.district.code = ''
          self.subdistrict.th = ''
          self.subdistrict.en = ''
          self.subdistrict.code = ''
          self.postcode = ''
        }
        self.getProvinceSuggestList(() => {
          if (self.langMode === 'en') {
            const index = self.provinceEnSuggestList.map(p => p.name).indexOf(self.province.en)
            if (index >= 0) {
              self.province.th = self.provinceThSuggestList[index].name
            } else {
              self.province.code = ''
            }
          }
        })
      })
    },
    'postcode' (newPostcode) {
      const self = this
      let isValid = true
      newPostcode = (newPostcode || '').trim()
      const isPostcode = (/^\d{5}?$/g.test(newPostcode))
      if (!isPostcode) {
        isValid = false
      }
      self.isValidPostcode = isValid
    },
    'country.th' (newCountryTh) {
      const self = this
      let isValid = true
      newCountryTh = (newCountryTh || '').trim()
      if (!(newCountryTh || false)) {
        isValid = false
      }
      self.isValidCountry.th = isValid
    },
    'country.en' (newCountryEn) {
      const self = this
      let isValid = true
      newCountryEn = (newCountryEn || '').trim()
      if (!(newCountryEn || false)) {
        isValid = false
      }
      if (/[\u0E00-\u0E7F]/.test(newCountryEn)) {
        isValid = false
      }
      self.isValidCountry.en = isValid
    },
    showMore () {
      const self = this
      const scrollTop = document.querySelector('.create-place > .form').scrollTop
      self.$nextTick(() => {
        if (self.showMore) {
          document.querySelector('.create-place > .form').scrollTop = scrollTop
        }
        self.onWindowResize()
      })
    },
    userData () {
      const self = this
      self.$nextTick(() => {
        // self.user = self.userData
        self.getTypeList()
      })
    },
    async isShowAlertBeforeSave () {
      const self = this
      self.$nextTick(() => {
        if (self.countDownConfirmTask) {
          clearInterval(self.countDownConfirmTask)
        }
        if (self.isShowAlertBeforeSave) {
          self.countDownConfirm = 5
          self.countDownConfirmTask = setInterval(() => {
            self.countDownConfirm--
            if (self.countDownConfirm === -1) {
              clearInterval(self.countDownConfirmTask)
            }
          }, 1000)
        } else {
          self.countDownConfirm = 0
        }
      })
    }
  },
  beforeDestroy () {
    const self = this
    window.removeEventListener('resize', self.onWindowResize)
  }
}
</script>

<style lang="scss" scoped>
.create-place {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 150;
  overflow: hidden;
  overflow-y: auto;

  .header {
    width: 100%;
    height: 54px;
    background-color: $darkPrimary;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: linear-gradient(0deg, $primary, $darkPrimary);
    border-bottom: 4px solid $lightPrimary;

    .title {
      font-family: Prompt;
      color: white;
      font-size: 18px;
      text-align: center;
    }

    button.close {
      padding: 0px;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0%, -50%);
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: white;
        font-size: 24px;
      }
    }
  }

  .form {
    width: 100%;
    height: calc(100% - 54px - 48px);
    overflow: hidden;
    overflow-y: auto;

    &.hide-title-bar {
      height: calc(100% - 48px);
    }

    .longdo-map-container {
      width: 100%;
      height: 35vh;
      min-height: 180px;
      position: relative;

      button {
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 0px;
        width: 32px;
        height: 32px;
        z-index: 10;
        border-radius: 16px;
        border: none;
        background-color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);

        &.base-map-layer {
          right: 100px
        }

        &.current-location {
          right: 56px;
        }

        i {
          font-size: 20px;
          color: white;
        }

        &.off {
          background-color: $lightGrey;
        }
      }

      .marker-crosshair {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        z-index: 10;
      }

      .location {
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 0px 6px 0px 3px;
        border-top-right-radius: 6px;
      }
    }

    .admin-section {
      margin: 12px 12px 0px 12px;
      padding: 0px 0px 12px 0px;
      box-sizing: border-box;
      background-color: rgb(255, 230, 230);
      border-radius: 12px;
      width: calc(100% - 24px);

      @media only screen and (max-width: 480px) {
        .multi-field-in-row {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .field {
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
          }

          .field:first-child {
            padding-left: 12px;
          }

          .field:last-child {
            padding-right: 12px;
          }
        }
      }
    }

    .address-section {
      margin: 12px 12px 0px 12px;
      padding: 0px 0px 12px 0px;
      box-sizing: border-box;
      background-color: rgba(175, 220, 255, 0.1);
      border-radius: 12px;
      width: calc(100% - 24px);
    }

    .field {
      display: flex;
      flex-direction: column;
      padding: 12px 12px 0px 12px;
      box-sizing: border-box;

      &.warning-privacy {
        font-family: Prompt;
        color: rgba(220, 20, 60, 0.75);
        background-color: rgba(220, 20, 60, 0.05);
        margin: 12px;
        width: calc(100% - 24px);
        font-size: 12px;
        padding: 12px;
      }

      label {
        font-family: Prompt;
        font-weight: bold;
        font-size: 16px;
      }

      .warning {
        font-family: Prompt;
        color: rgba(220, 20, 60, 0.75);
        background-color: rgba(220, 20, 60, 0.05);
        width: calc(100% - 24px);
        font-size: 12px;
        padding: 12px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 6px 0px;

        i {
          color: rgba(220, 20, 60, 0.75);
          margin-right: 6px;
          font-size: 20px;
        }
      }

      input:not([type="checkbox"]) {
        outline: none;
        font-size: 16px;
        padding: 0px 12px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: 100%;
        height: 48px;
        line-height: 48px;
        appearance: none;
        margin-top: 6px;

        &::placeholder {
          color: #afafaf;
          font-family: Prompt;
        }

        &.invalid {
          border: 1px solid $red;
        }
      }

      textarea {
        outline: none;
        font-size: 16px;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: 100%;
        min-height: 42px;
        line-height: 21px;
        appearance: none;
        resize: none;
        margin-top: 6px;

        &::placeholder {
          color: #afafaf;
          font-family: Prompt;
        }

        &.invalid {
          border: 1px solid $red;
        }
      }

      .guide {
        color: #afafaf;
        font-size: 12px;
        line-height: 1.5;
        padding-top: 3px;
        font-family: Prompt;
      }

      &.full-description {
        /deep/ h1, /deep/ h1 * {
          font-size: 48px;
        }
        /deep/ h2, /deep/ h2 * {
          font-size: 42px;
        }
        /deep/ h3, /deep/ h3 * {
          font-size: 36px;
        }
        /deep/ h4, /deep/ h4 * {
          font-size: 30px;
        }
        /deep/ h5, /deep/ h5 * {
          font-size: 24px;
        }
        /deep/ h6, /deep/ h6 * {
          font-size: 18px;
        }
      }
    }

    .multi-field-in-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .field {
        flex: 1;
        padding-left: 6px;
        padding-right: 6px;

        &.house-no {
          width: 15%;
          flex: unset;
        }

        &.moo {
          width: 15%;
          flex: unset;
        }

        &.village {
          width: 70%;
          flex: unset;
        }

        &.condo {
          width: 35%;
          flex: unset;
        }

        &.building {
          width: 35%;
          flex: unset;
        }

        &.floor {
          width: 15%;
          flex: unset;
        }

        &.room-no {
          width: 15%;
          flex: unset;
        }
      }

      .field:first-child {
        padding-left: 12px;
      }

      .field:last-child {
        padding-right: 12px;
      }
    }

    @media only screen and (max-width: 480px) {
      .multi-field-in-row {
        .field {
          flex: 1;
          padding-left: 12px;
          padding-right: 12px;

          &.latitude {
            width: 50%;
            flex: unset;
          }

          &.longitude {
            width: 50%;
            flex: unset;
          }

          &.category {
            width: 100%;
            flex: unset;
          }

          &.place-name-th {
            width: 100%;
            flex: unset;
          }

          &.place-name-en {
            width: 100%;
            flex: unset;
          }

          &.house-no {
            width: 50%;
            flex: unset;
          }

          &.moo {
            width: 50%;
            flex: unset;
          }

          &.village {
            width: 100%;
            flex: unset;
          }

          &.condo {
            width: 50%;
            flex: unset;
          }

          &.building {
            width: 50%;
            flex: unset;
          }

          &.floor {
            width: 50%;
            flex: unset;
          }

          &.room-no {
            width: 50%;
            flex: unset;
          }
        }

        .field:first-child {
          padding-left: 12px;
        }

        .field:last-child {
          padding-right: 12px;
        }
      }
    }

    button {
      height: 28px;
      background-color: transparent;
      padding: 0 12px;
      border-radius: 14px;
      color: $primary;
      border: 0px solid $primary;
      font-family: Prompt;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    button.add-contact {
      height: 28px;
      background-color: white;
      padding: 0px 12px 0px 6px;
      border-radius: 14px;
      color: $primary;
      border: 1px solid $primary;
      font-family: Prompt;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 12px auto 12px auto;

      i {
        color: $primary;
        margin-right: 3px;
      }
    }

    button.add-more {
      height: 28px;
      background-color: white;
      padding: 0px 12px 0px 6px;
      border-radius: 14px;
      color: $primary;
      border: 1px solid $primary;
      font-family: Prompt;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 12px auto;

      i {
        color: $primary;
        margin-right: 3px;
      }
    }

    .preview-image-list {
      margin: 6px 0px 0px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    button.add-image {
      border: 2px solid $primary;
      border-radius: 6px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      font-family: Prompt;
      color: $primary;
      box-sizing: border-box;

      i {
        font-size: 32px;
        color: $primary;
      }
    }

    .day {
      margin: 12px 0px;

      .name {
        width: 110px;
      }

      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 27px;

        .topic {
          width: 60px;
        }

        .hour {
          width: calc((100% - 60px - 12px) / 2);
          margin: 0px 6px 0px 6px;
        }

        .min {
          width: calc((100% - 60px - 12px) / 2);
          margin: 0px 6px 0px 6px;
        }

        .all-day {
          margin-top: 6px;
        }
      }
    }
  }

  .action {
    width: 100%;
    height: 48px;

    button {
      width: 100%;
      height: 100%;
      border: none;
      background-color: $darkPrimary;
      color: white;
      font-family: Prompt;
      font-size: 18px;
      transition: background-color 0.3s ease 0s;

      &:disabled {
        background-color: $lightGrey;
      }
    }
  }

  .alert-before-save {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-card {
      padding: 24px;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;
      display: flex;
      flex-direction: column;
      min-width: 300px;

      .msg {
        font-family: Prompt;
        font-size: 16px;
        color: $red;

        i {
          font-size: 36px;
          color: $tangerine;
        }
      }

      .action {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 12px;

        button {
          font-size: 1.0rem;
          height: 36px;
          background-color: $primary;
          padding: 12px 6px;
          border-radius: 18px;
          color: white;
          border: 1px solid $primary;
          font-family: Prompt;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 0px 6px;
          transition: background-color 0.3s ease 0s,
            color 0.3s ease 0s;

          &.outline {
            border-color: $red;
            color: $red;
            background-color: white;
          }

          &:disabled {
            background-color: $lightGrey;
            border: 1px solid $lightGrey;
            cursor: default;
          }

          &:disabled.outline {
            color: $lightGrey;
            background-color: white;
          }
        }
      }
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 250;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);

    .error-message,
    .success-message {
      font-family: 'Prompt';
      font-size: 16px;
      padding: 12px;
      text-align: center;
      color: rgb(255, 255, 255);
      max-width: 230px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
    }
  }
}
</style>
