<template>
  <div ref="dropdown-widget" class="dropdown-widget">
    <div class="selected" :class="{disabled: disabled}" @click="clickInput">
      <div class="image-container" v-if="selectedIndex !== null && (options[selectedIndex].image || '') !== ''">
        <img :src="options[selectedIndex].image">
      </div>
      <div class="name" v-if="selectedIndex !== null">
        {{ $i18n.locale === 'th' ? options[selectedIndex].label : options[selectedIndex].labelEn }}
      </div>
      <div class="placeholder" v-if="selectedIndex === undefined || selectedIndex === null">
        {{ placeholder || `${$t('dropdownWidget.select')}` }} {{ isRequired ? '*' : '' }}
      </div>
      <i class="material-icons-round arrow-dropdown" :class="{active: options.length > 0 && showDropdown}">arrow_drop_down</i>
    </div>
    <div ref="dropdown" class="dropdown" v-show="options.length > 0 && showDropdown">
      <input ref="keyword"  type="search" autocomplete="none" v-model="keyword" @keydown.down="down" @keydown.up="up" v-if="hasFilter">
      <div ref="option-list" class="option-list">
        <div class="option-row"
          @click="select('')"
          :class="{'selected-row': '' === selectedValue}"
          v-if="!isRequired"
        >
          <div class="name">
            {{ $t('dropdownWidget.unselect') }}
          </div>
        </div>
        <div class="option-row"
          v-for="option in filteredOptions"
          :key="option.value" @click="select(option.value)"
          :class="{'selected-row': option.value === selectedValue}">
          <div class="image-container" v-if="(option.image || '') !== ''">
            <img :src="option.image" loading="lazy">
          </div>
          <div class="name">
            {{ $i18n.locale === 'th' ? option.label : option.labelEn }}
          </div>
        </div>
        <div v-if="filteredOptions.length == 0" class="option-row no-result">
          <div class="name">
            {{  $t('searchResultPanel.empty') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownWidget',
  props: {
    value: null,
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasFilter: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      keyword: '',
      showDropdown: false,
      selectedValue: null,
      selectedIndex: null
    }
  },
  mounted () {
    var self = this
    window.addEventListener('click', self.clickOnWindow)
    window.addEventListener('touchstart', self.clickOnWindow)
  },
  computed: {
    filteredOptions () {
      var self = this
      return (self.options || []).filter(option => {
        var isMatchTh = option.label.toLowerCase().indexOf(self.keyword.toLowerCase()) !== -1
        var isMatchEn = option.labelEn.toLowerCase().indexOf(self.keyword.toLowerCase()) !== -1
        var isEmptyKeyword = self.keyword === ''
        return isMatchTh || isMatchEn || isEmptyKeyword
      })
    }
  },
  methods: {
    clickOnWindow (e) {
      var self = this
      if ((self.options || []).length > 0 && self.showDropdown) {
        self.showDropdown = (self.$refs['dropdown-widget'] === e.target || self.$refs['dropdown-widget'].contains(e.target))
      }
    },
    clickInput () {
      var self = this
      if (self.disabled) {
        return false
      }
      self.showDropdown = !self.showDropdown
      self.$nextTick(() => {
        if (!(self.options.length > 0 && self.showDropdown)) {
          return false
        }
        const el = self.$refs['option-list']
        if (el) {
          const rect = el.getBoundingClientRect()
          const isInViewport = (rect.top - 54) >= 0 &&
            rect.left >= 0 &&
            (rect.bottom - 54) <= ((window.innerHeight || document.documentElement.clientHeight) - 54 - 48) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          if (!isInViewport) {
            el.scrollIntoView(false)
          }
        }
      })
      // self.$nextTick(() => self.$refs.keyword.focus())
    },
    select (value) {
      var self = this
      self.selectedValue = value
      var index = (self.options || []).map(option => { return option.value }).indexOf(self.selectedValue)
      self.selectedIndex = index > -1 ? index : null
      self.$nextTick(() => {
        self.showDropdown = false
      })
    },
    down () {
      var self = this
      if (self.selectedValue !== null) {
        var index = self.filteredOptions.map(option => { return option.value }).indexOf(self.selectedValue)
        if (index !== -1) {
          index++
          if (index >= self.filteredOptions.length) {
            self.selectedIndex = self.filteredOptions.length - 1
          } else {
            self.selectedIndex = index
          }
          self.selectedValue = self.filteredOptions[self.selectedIndex].value
        }
      } else {
        self.selectedIndex = 0
        self.selectedValue = self.filteredOptions[self.selectedIndex].value
      }
    },
    up () {
      var self = this
      if (self.selectedValue !== null) {
        var index = self.filteredOptions.map(option => { return option.value }).indexOf(self.selectedValue)
        if (index !== -1) {
          index--
          if (index < 0) {
            self.selectedIndex = 0
          } else {
            self.selectedIndex = index
          }
          self.selectedValue = self.filteredOptions[self.selectedIndex].value
        }
      } else {
        self.selectedIndex = 0
        self.selectedValue = self.filteredOptions[self.selectedIndex].value
      }
    },
    updateScrollTop () {
      var selectedRow = document.querySelector('.selected-row')
      if (selectedRow) {
        selectedRow.scrollIntoView()
      }
    }
  },
  watch: {
    showDropdown () {
      var self = this
      self.$nextTick(() => {
        if (self.showDropdown) {
          // self.updateScrollTop()
        }
      })
    },
    selectedIndex () {
      var self = this
      self.$nextTick(() => {
        // self.updateScrollTop()
        if (self.selectedIndex === null) {
          self.$emit('input', {
            value: '',
            label: '',
            labelEn: ''
          })
        } else {
          var index = (self.options || []).map(option => { return option.value }).indexOf(self.selectedValue)
          self.$emit('input', self.options[index])
        }
      })
    },
    options () {
      var self = this
      self.$nextTick(() => {
        if (self.selectedValue !== '') {
          var index = (self.options || []).map(option => { return option.value }).indexOf(self.selectedValue)
          self.selectedIndex = index > -1 ? index : null
        }
      })
    },
    value: {
      immediate: true,
      handler (newValue) {
        var self = this
        self.$nextTick(() => {
          self.selectedValue = newValue.value
          var index = (self.options || []).map(option => { return option.value }).indexOf(self.selectedValue)
          self.selectedIndex = index > -1 ? index : null
        })
      }
    }
  },
  beforeDestroy () {
    var self = this
    window.removeEventListener('click', self.clickOnWindow)
    window.removeEventListener('touchstart', self.clickOnWindow)
  }
}
</script>

<style scoped lang="scss">
.dropdown-widget {
  position: relative;

  .selected {
    outline: none;
    font-size: 16px;
    padding: 0px 39px 0px 12px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    width: 100%;
    height: 42px;
    line-height: 42px;
    appearance: none;
    user-select: none;
    margin-top: 3px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: white;

    &.disabled {
      background-color: $extraLightGrey;
    }

    .image-container {
      height: 24px;
      width: 24px;
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;

      img {
        max-height: 24px;
        max-width: 24px;
      }
    }

    .name {
      width: 100%;
      padding: 0px 0px 0px 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      font-size: 16px;
    }

    .placeholder {
      width: 100%;
      padding: 2px 0px 0px 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      color: #afafaf;
      font-family: Prompt;
      font-size: 16px;
    }

    .arrow-dropdown {
      position: absolute;
      top: 50%;
      right: 3px;
      transform: translate(0%, calc(0px - 50%)) rotate(0deg);
      transition: transform 0.3s ease 0s;
      z-index: 10;
      font-size: 36px;
      color: $primary;

      &.active {
        transform: translate(0%, calc(0px - 50%)) rotate(180deg);
      }
    }
  }

  .dropdown {
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 100%;
    top: 100%;
    left: 0px;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    z-index: 20;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    input[type=search] {
      outline: none;
      font-size: 16px;
      padding: 0px 12px;
      margin: 12px 12px;
      box-sizing: border-box;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      width: calc(100% - 24px);
      height: 42px;
      line-height: 42px;
      appearance: none;
      margin-top: 12px;
    }

    .option-list {
      overflow-y: auto;
      max-height: 200px;

      &::-webkit-scrollbar {
        background-color: rgb(251, 251, 251);
      }
      &::-webkit-scrollbar-thumb {
        border: 2px solid rgb(251, 251, 251);
      }

      .option-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 42px;
        padding: 9px 12px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          background-color: rgba(56, 148, 238, 0.1);
        }

        &.selected-row {
          background-color: rgba(56, 148, 238, 0.1);

          .name {
            color: #3894ee;
          }
        }

        .image-container {
          height: 24px;
          width: 24px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;

          img {
            max-height: 24px;
            max-width: 24px;
          }
        }

        .name {
          width: 100%;
          padding: 2px 0px 0px 0px;
          font-size: 16px;
        }
      }

      .option-row.no-result {
          :hover{
            cursor: not-allowed;
          }
          .name {
            text-align: center;
            color: #afafaf;
          }
        }
    }
  }
}
</style>
